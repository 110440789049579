import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import withTranslations from 'app/components/HOGs/withTranslations';
import { Form, Checkbox, Button } from 'antd';
import { newsAPI } from 'app/API/news.api';
import { openNotificationWithIcon } from 'app/components/Utility/notificationMessage';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import FormItem from './formItem';
import AddPictures from './addPictures';
import ImagesGallery from './imagesGallery';
import './clubNews.scss';

const langs = ['en', 'da', 'de'];
const fullLangName = ['English', 'Danish', 'German'];

const ClubNews = (props) => {
  const apiErrorHandler = (err) =>
    openNotificationWithIcon('error', err.message);
  const apiSuccesHandler = (mess) => openNotificationWithIcon('success', mess);

  const { tr } = props;

  const clubData = useSelector((store) => store.clubFullDataReducer.data);

  const [form] = Form.useForm();

  const [multy, setMulty] = useState(false);
  const [notSend, setNotSend] = useState(false);
  const [commentId, setCommentId] = useState(null);
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);

  const reset = () => {
    setImages([]);
    setCommentId(null);
    setMulty(false);
    setNotSend(false);
    form.resetFields();
    hideLoading();
  };

  const onFinish = (values) => {
    showLoading();
    const { title, text } = values;
    const data = {
      scope: 'club',
      parent_id: 0,
      comments_title: title.single,
      comments_text: text.single,
      comment_id: commentId,
      cobject_id: clubData.id,
      wla: {
        active: 1,
        multylang: +multy,
        doNotSend: +notSend,
        en_text: text.en || '',
        da_text: text.da || '',
        de_text: text.de || '',
        en_title: title.en || '',
        da_title: title.da || '',
        de_title: title.de || '',
      },
    };
    newsAPI
      .add(data)
      .then((resp) => {
        const { code, response } = resp;
        if (code === 200 && response.history.id) {
          apiSuccesHandler(response.message);
        } else {
          apiErrorHandler(resp);
        }
      })
      .then(() => reset())
      .catch((err) => {
        reset();
        apiErrorHandler(err);
      });
  };

  const onChange = (e) => {
    if (e.target.name === 'single') {
      setMulty(false);
    } else {
      setMulty(true);
    }
  };

  const openClick = () => {
    setOpen(!open);
  };

  const deletePictures = (img) => {
    images.forEach((item) => {
      if (item === img) {
        images.splice(images.indexOf(item), 1);
      }
    });

    setImages([...images]);
  };

  return (
    <div className="club_news">
      <div className="title" onClick={openClick} aria-hidden>
        {tr.News}
      </div>
      <Form
        name="form"
        onFinish={onFinish}
        form={form}
        className={open ? 'open' : ''}
      >
        {multy ? (
          langs.map((item, i) => (
            <FormItem
              lang={item}
              multy={multy}
              fullName={fullLangName[i]}
              key={item}
            />
          ))
        ) : (
          <FormItem lang="single" multy={multy} />
        )}
        <Form.Item className="publish">
          <Button type="primary" htmlType="submit">
            {tr.Publish}
          </Button>
        </Form.Item>
      </Form>
      <div className={`ext_settings ${open ? 'open' : ''}`}>
        <ImagesGallery
          images={images}
          setImages={setImages}
          deletePictures={deletePictures}
        />
        <AddPictures
          images={images}
          setImages={setImages}
          id={clubData.id}
          commentId={commentId}
          setCommentId={setCommentId}
        />
        <Checkbox
          checked={!multy}
          name="single"
          onChange={onChange}
          className="single"
        >
          {tr['Same message to all users']}
        </Checkbox>
        <Checkbox
          checked={multy}
          name="multy"
          onChange={onChange}
          className="multy"
        >
          {tr['Translate to all languages']}
        </Checkbox>
        <Checkbox
          checked={notSend}
          onChange={(e) => setNotSend(e.target.value)}
          className="sent"
        >
          {tr['Do not send to iPhone/Android']}
        </Checkbox>
      </div>
    </div>
  );
};

export default withTranslations(ClubNews);
