//
export const ADD_POINTS = 'ADD_POINTS';

export const GET_META_TAGS_FOR_SEO = 'GET_META_TAGS_FOR_SEO';
export const GET_META_TAGS_FOR_SEO_PENDING = 'GET_META_TAGS_FOR_SEO_PENDING';
export const GET_META_TAGS_FOR_SEO_REJECTED = 'GET_META_TAGS_FOR_SEO_REJECTED';
export const GET_META_TAGS_FOR_SEO_FULFILLED =
  'GET_META_TAGS_FOR_SEO_FULFILLED';
// Users
export const GET_CURRENT_LOCATION_USER = 'GET_CURRENT_LOCATION_USER';
export const SET_HOME_ADDRESS_USER = 'SET_HOME_ADDRESS_USER';
export const SET_WORK_ADDRESS_USER = 'SET_WORK_ADDRESS_USER';
export const SET_LAST_OPENED_OBJECT = 'SET_LAST_OPENED_OBJECT';
export const SET_USER_HISTORY_DATA = 'SET_USER_HISTORY_DATA';
export const SET_USER_PICTURES_DATA = 'SET_USER_PICTURES_DATA';
export const USER_FILTER_OPEN_STATE = 'USER_FILTER_OPEN_STATE';
export const USER_CHANGE_SHOW_HOME = 'USER_CHANGE_SHOW_HOME';
export const USER_CHANGE_SHOW_WORK = 'USER_CHANGE_SHOW_WORK';
export const USER_CHANGE_INDEX_VALUE = 'USER_CHANGE_INDEX_VALUE';
//
// User history
export const USER_HISTORY = 'USER_HISTORY';
export const USER_HISTORY_STARTED = 'USER_HISTORY_STARTED';
export const USER_HISTORY_FAILURE = 'USER_HISTORY_FAILURE';
export const USER_HISTORY_SUCCESS = 'USER_HISTORY_SUCCESS';
export const USER_HISTORY_CHANGE_SCOPE = 'USER_HISTORY_CHANGE_SCOPE';
//
export const USER_SET_NOTIFICATION_OPTIONS = 'USER_SET_NOTIFICATION_OPTIONS';
export const USER_SET_PRIVACY_SETTINGS = 'USER_SET_PRIVACY_SETTINGS';
export const USER_SET_MAIN_DATA = 'USER_SET_MAIN_DATA';
export const USER_SET_MEDIA_DATA = 'USER_SET_MEDIA_DATA';
export const USER_CHANGE_MAIN_DATA = 'USER_CHANGE_MAIN_DATA';
// Map
export const SET_MAP_SCOPE = 'SET_MAP_SCOPE';
export const PASS_POINT_TO_SCOPE = 'PASS_POINT_TO_SCOPE';
export const MOVE_POINT_SUCCESS = 'MOVE_POINT_SUCCESS';
export const MAP_POSITION = 'MAP_POSITION';
export const MAP_BOUNDS_STR_ZOOM = 'MAP_BOUNDS_STR_ZOOM';
export const MAP_DETECT_CLICK_EVENT = 'MAP_DETECT_CLICK_EVENT';
export const MAP_DETECT_COORD = 'MAP_DETECT_COORD';
export const MAP_SET_FILTER = 'MAP_SET_FILTER';
export const FETCH_TOURS_PUBLIC = 'FETCH_TOURS_PUBLIC';
export const FETCH_TOURS_PUBLIC_PENDING = 'FETCH_TOURS_PUBLIC_PENDING';
export const FETCH_TOURS_PUBLIC_REJECTED = 'FETCH_TOURS_PUBLIC_REJECTED';
export const FETCH_TOURS_PUBLIC_FULFILLED = 'FETCH_TOURS_PUBLIC_FULFILLED';
export const FETCH_PREVIEW_DATA = 'FETCH_PREVIEW_DATA';
export const FETCH_PREVIEW_DATA_PENDING = 'FETCH_PREVIEW_DATA_PENDING';
export const FETCH_PREVIEW_DATA_REJECTED = 'FETCH_PREVIEW_DATA_REJECTED';
export const FETCH_PREVIEW_DATA_FULFILLED = 'FETCH_PREVIEW_DATA_FULFILLED';
export const FETCH_PREVIEW_DATA_HIPERSET = 'FETCH_PREVIEW_DATA_HIPERSET';
export const FETCH_PREVIEW_DATA_CLEANE = 'FETCH_PREVIEW_DATA_CLEANE';
export const FETCH_PREVIEW_POLYLINE = 'FETCH_PREVIEW_POLYLINE';
export const FETCH_PREVIEW_POLYLINE_PENDING = 'FETCH_PREVIEW_POLYLINE_PENDING';
export const FETCH_PREVIEW_POLYLINE_REJECTED =
  'FETCH_PREVIEW_POLYLINE_REJECTED';
export const FETCH_PREVIEW_POLYLINE_FULFILLED =
  'FETCH_PREVIEW_POLYLINE_FULFILLED';
export const FETCH_PREVIEW_POLYLINE_CLEANE = 'FETCH_PREVIEW_POLYLINE_CLEANE';
export const UPDATE_MAP_ITEM = 'UPDATE_MAP_ITEM';
export const DELETE_MAP_ITEM = 'DELETE_MAP_ITEM';
export const DELETE_MAP_ITEM_BY_ID = 'DELETE_MAP_ITEM_BY_ID';

// Tourstart Pro
export const PRO_DATA_INIT = 'PRO_DATA_INIT';
export const PRO_DATA_PRICE = 'PRO_DATA_PRICE';
export const PRO_DATA_CHANGE_SELECTED_CURRENCY =
  'PRO_DATA_CHANGE_SELECTED_CURRENCY';
export const PRO_DATA_CHANGE_VOUCHER_DATA = 'PRO_DATA_CHANGE_VOUCHER_DATA';
// Login
export const LOGIN_DATA_IS_LOGIN = 'LOGIN_DATA_IS_LOGIN';
export const LOGIN_DATA_MODAL_OPEN = 'LOGIN_DATA_MODAL_OPEN';
export const LOGIN_DATA_PRO_MODAL_OPEN = 'LOGIN_DATA_PRO_MODAL_OPEN';
export const LOGIN_DATA_LEGAL_ISSUES_OPEN = 'LOGIN_DATA_LEGAL_ISSUES_OPEN';
export const SET_IS_UNREGISTER_USER_DATA = 'SET_IS_UNREGISTER_USER_DATA';
export const SET_IS_USER_CONFIRM_LEGAL_ISSUES =
  'SET_IS_USER_CONFIRM_LEGAL_ISSUES';
export const IS_FAQ_MODAL_OPEN = 'IS_FAQ_MODAL_OPEN';
export const SET_FAQ_PARENT_ID = 'SET_FAQ_PARENT_ID';
// Tour Full View
export const TOUR_FULL_POINT_DATA = 'TOUR_FULL_POINT_DATA';
export const TOUR_FULL_DATA_ID = 'TOUR_FULL_DATA_ID';
export const TOUR_FULL_DATA = 'TOUR_FULL_DATA';
export const TOUR_FULL_DATA_PENDING = 'TOUR_FULL_DATA_PENDING';
export const TOUR_FULL_DATA_REJECTED = 'TOUR_FULL_DATA_REJECTED';
export const TOUR_FULL_DATA_FULFILLED = 'TOUR_FULL_DATA_FULFILLED';
export const TOUR_FULL_OPENED_MULTI_PART = 'TOUR_FULL_OPENED_MULTI_PART';
export const TOUR_FULL_ACTIVE_MULTI_PART_POINT =
  'TOUR_FULL_ACTIVE_MULTI_PART_POINT';
export const TOUR_FULL_SET_ACTIVE_MULTI_PART =
  'TOUR_FULL_SET_ACTIVE_MULTI_PART';
export const TOUR_FULL_IS_EDIT_MODE = 'TOUR_FULL_IS_EDIT_MODE';
export const TOUR_FULL_SET_ARROW_TYPE = 'TOUR_FULL_SET_ARROW_TYPE';
export const TOUR_FULL_SET_ACCESSILITY_TYPE = 'TOUR_FULL_SET_ACCESSILITY_TYPE';
export const TOUR_FULL_SET_POST_COUNTED_DATA =
  'TOUR_FULL_SET_POST_COUNTED_DATA';
export const TOUR_FULL_SET_POINT_TYPE = 'TOUR_FULL_SET_POINT_TYPE';
export const PUBLIC_TOUR_TYPE_REQUIRED_FILED =
  'PUBLIC_TOUR_TYPE_REQUIRED_FILED';
export const TOUR_FULL_DATA_COPY_ID = 'TOUR_FULL_DATA_COPY_ID';
export const TOUR_FULL_DATA_POLYLINE = 'TOUR_FULL_DATA_POLYLINE';
export const TOUR_FULL_DATA_POLYLINE_PENDING =
  'TOUR_FULL_DATA_POLYLINE_PENDING ';
export const TOUR_FULL_DATA_POLYLINE_REJECTED =
  'TOUR_FULL_DATA_POLYLINE_REJECTED';
export const TOUR_FULL_DATA_POLYLINE_FULFILLED =
  'TOUR_FULL_DATA_POLYLINE_FULFILLED';
export const TOUR_FULL_DATA_MAP_POSITION = 'TOUR_FULL_DATA_MAP_POSITION';
export const TOUR_FULL_DATA_PENDING_DAY = 'TOUR_FULL_DATA_PENDING_DAY';
export const TOUR_FULL_DATA_ADD_NEW_DAY = 'TOUR_FULL_DATA_ADD_NEW_DAY';
export const TOUR_FULL_DATA_ADD_VIA_DAY = 'TOUR_FULL_DATA_ADD_VIA_DAY';
export const TOUR_FULL_DATA_UPDATE_DAY = 'TOUR_FULL_DATA_UPDATE_DAY';
export const TOUR_FULL_PART_POINTS_DATA_UPDATE =
  'TOUR_FULL_PART_POINTS_DATA_UPDATE';
export const TOUR_FULL_DATA_UPDATE_DAY_WITH_TIMESTAMP =
  'TOUR_FULL_DATA_UPDATE_DAY_WITH_TIMESTAMP';
export const TOUR_FULL_DATA_DELETE_DAY = 'TOUR_FULL_DATA_DELETE_DAY';
export const TOUR_FULL_DATA_MAKE_MULTIDAYS_TOUR =
  'TOUR_FULL_DATA_MAKE_MULTIDAYS_TOUR';

export const TOUR_CLEAR_TOUR_PART = 'TOUR_CLEAR_TOUR_PART';

export const TOUR_FULL_INFO_UPDATE_DAY = 'TOUR_FULL_INFO_UPDATE_DAY';
export const TOUR_FULL_DATA_SET_DESCRIPTION = 'TOUR_FULL_DATA_SET_DESCRIPTION';

export const USER_TOUR_AMOUNT = 'USER_TOUR_AMOUNT';
export const USER_TOUR_AMOUNT_FULFILLED = 'USER_TOUR_AMOUNT_FULFILLED';
export const USER_TOUR_AMOUNT_COPY_FULFILLED =
  'USER_TOUR_AMOUNT_COPY_FULFILLED';
export const TOUR_FULL_DATA_ADD_OWNER = 'TOUR_FULL_DATA_ADD_OWNER';
export const TOUR_FULL_RESAVE = 'TOUR_FULL_RESAVE';
export const TOUR_FULL_SET_MAIN_PART_DISTANCE_DURATION =
  'TOUR_FULL_SET_MAIN_PART_DISTANCE_DURATION';
export const TOUR_FULL_SET_PART_DISTANCE_DURATION_BY_ID =
  'TOUR_FULL_SET_PART_DISTANCE_DURATION_BY_ID';
export const SAVE_FIRST_DAY_WHEN_MULTIDAY_ON =
  'SAVE_FIRST_DAY_WHEN_MULTIDAY_ON';
export const TOUR_FULL_SELECT_POINT = 'TOUR_FULL_SELECT_POINT';
export const TOUR_FULL_OPTIMIZE_SAVE = 'TOUR_FULL_OPTIMIZE_SAVE';
export const TOUR_FULL_GPS_DATA_LODING_STATUS =
  'TOUR_FULL_GPS_DATA_LODING_STATUS';
export const TOUR_FULL_DISABLE_RELOAD_COMMON_PAGE =
  'TOUR_FULL_DISABLE_RELOAD_COMMON_PAGE';
export const TOUR_FULL_DATA_SAVE_PREV_PART_POINT =
  'TOUR_FULL_DATA_SAVE_PREV_PART_POINT';
export const TOUR_FULL_CHANGE_PART_ID = 'TOUR_FULL_CHANGE_PART_ID';
export const TOUR_FULL_DATA_IMPORTED = 'TOUR_FULL_DATA_IMPORTED';
export const TOUR_FULL_ROUTE_SUGGESTION = 'TOUR_FULL_ROUTE_SUGGESTION';
export const TOUR_FULL_UPDATE_MAIN_PART_TITLE =
  'TOUR_FULL_UPDATE_MAIN_PART_TITLE';
export const TOUR_FULL_ACTIVE_MODE = 'TOUR_FULL_ACTIVE_MODE';
export const TOUR_FULL_SET_FREE_HAND = 'TOUR_FULL_SET_FREE_HAND';
export const TOUR_FULL_IS_CLEAR_TOUR = 'TOUR_FULL_IS_CLEAR_TOUR';
export const TOUR_FULL_SET_TWISTER_DATA = 'TOUR_FULL_SET_TWISTER_DATA';

// SEARCH
export const SEARCH_BAR_SCOPE = 'SEARCH_BAR_SCOPE';
export const SEARCH_BAR_ROAD_TYPES = 'SEARCH_BAR_ROAD_TYPES';

// Drive data
export const ADD_POINT_TO_ACTIVE_TOUR_DAY = 'ADD_POINT_TO_ACTIVE_TOUR_DAY';
export const TOUR_FULL_DATA_LAST_SEARCH_RESULT =
  'TOUR_FULL_DATA_LAST_SEARCH_RESULT';

// TOURDOWNLOAD
export const TOUR_DOWNLOAD_GPS_MODAL_OPEN = 'TOUR_DOWNLOAD_GPS_MODAL_OPEN';
export const TOUR_DOWNLOAD_GPS_BASKET_OPEN = 'TOUR_DOWNLOAD_GPS_BASKET_OPEN';
export const TOUR_DOWNLOAD_GPS_DATA = 'TOUR_DOWNLOAD_GPS_DATA';
export const TOUR_DOWNLOAD_GPS_DATA_DELETE_FROM_BASKET =
  'TOUR_DOWNLOAD_GPS_DATA_DELETE_FROM_BASKET';
export const TOUR_DOWNLOAD_GPS_DATA_COORD = 'TOUR_DOWNLOAD_GPS_DATA_COORD';
export const TOUR_DOWNLOAD_GPS_DATA_CLEAR = 'TOUR_DOWNLOAD_GPS_DATA_CLEAR';
export const TOUR_DOWNLOAD_GPS_DATA_FROM_DB = 'TOUR_DOWNLOAD_GPS_DATA_FROM_DB';
// MAIN MENU
export const MAIN_MENU_COLLAPSE = 'MAIN_MENU_COLLAPSE';
export const SET_MENU_FOLDER_CREATE_VISIBLE = 'SET_MENU_FOLDER_CREATE_VISIBLE';
// USER ENTER
export const SET_STATE_USER_ENTER = 'SET_STATE_USER_ENTER';

// CSS EFFECTS
// export const IS_CONTENT_BLUR_UNDER_MODAL_MASK = 'IS_CONTENT_BLUR_UNDER_MODAL_MASK';
// export const IS_ENABLE_MODAL_MASK = 'IS_ENABLE_MODAL_MASK';
export const MOUSE_CURSOR_ICON_TYPE = 'MOUSE_CURSOR_ICON_TYPE';

//
export const MAP_DATA_INITIAL_STATE = 'MAP_DATA_INITIAL_STATE';
// LANGUAGE
export const LANGUAGE_DATA_CHANGE_ACTIVE = 'LANGUAGE_DATA_CHANGE_ACTIVE'; // MAIN MENU

// CLUB
export const FETCH_CLUBS_ALL = 'FETCH_CLUBS_ALL';
export const FETCH_CLUBS_ALL_PENDING = 'FETCH_CLUBS_ALL_PENDING';
export const FETCH_CLUBS_ALL_REJECTED = 'FETCH_CLUBS_ALL_REJECTED';
export const FETCH_CLUBS_ALL_FULFILLED = 'FETCH_CLUBS_ALL_FULFILLED';
export const CLUB_FULL_IS_EDIT_MODE = 'CLUB_FULL_IS_EDIT_MODE';
export const FETCH_CLUB_FULL_DATA = 'FETCH_CLUB_FULL_DATA';
export const FETCH_CLUB_FULL_DATA_PENDING = 'FETCH_CLUB_FULL_DATA_PENDING';
export const FETCH_CLUB_FULL_DATA_REJECTED = 'FETCH_CLUB_FULL_DATA_REJECTED';
export const FETCH_CLUB_FULL_DATA_FULFILLED = 'FETCH_CLUB_FULL_DATA_FULFILLED';
export const CLUB_CREATE_SET_ADDRESS = 'CLUB_CREATE_SET_ADDRESS';
export const CLUB_CREATE_SET_DATA = 'CLUB_CREATE_SET_DATA';
export const CLUB_SET_LOGO = 'CLUB_SET_LOGO';
export const CLUB_SET_COVER = 'CLUB_SET_COVER';
export const CLUB_ADD_VIDEO = 'CLUB_ADD_VIDEO';
export const CLUB_ADD_PICTURES = 'CLUB_ADD_PICTURES';
export const CLUB_ADD_IMAGE_IDS = 'CLUB_ADD_IMAGE_IDS';
export const CLUB_SET_DUBDATA = 'CLUB_SET_DUBDATA';
export const IS_ADMIN = 'IS_ADMIN';
export const CLUB_ADD_OPTIONS = 'CLUB_ADD_OPTIONS';
export const CLUB_ADD_ADDITIONALY = 'CLUB_ADD_ADDITIONALY';
export const CLUB_ADD_SERVICES = 'CLUB_ADD_SERVICES';
// BIKERBEDS
export const FETCH_BIKERBEDS_ALL = 'FETCH_BIKERBEDS_ALL';
export const FETCH_BIKERBEDS_ALL_PENDING = 'FETCH_BIKERBEDS_ALL_PENDING';
export const FETCH_BIKERBEDS_ALL_REJECTED = 'FETCH_BIKERBEDS_ALL_REJECTED';
export const FETCH_BIKERBEDS_ALL_FULFILLED = 'FETCH_BIKERBEDS_ALL_FULFILLED';

// PLACES
export const FETCH_PLACE_FULL_DATA = 'FETCH_PLACE_FULL_DATA';
export const FETCH_PLACE_FULL_DATA_PENDING = 'FETCH_PLACE_FULL_DATA_PENDING';
export const FETCH_PLACE_FULL_DATA_REJECTED = 'FETCH_PLACE_FULL_DATA_REJECTED';
export const FETCH_PLACE_FULL_DATA_FULFILLED =
  'FETCH_PLACE_FULL_DATA_FULFILLED';

export const FETCH_PLACE_ALL = 'FETCH_PLACE_ALL';
export const FETCH_PLACE_ALL_PENDING = 'FETCH_PLACE_ALL_PENDING';
export const FETCH_PLACE_ALL_REJECTED = 'FETCH_PLACE_ALL_REJECTED';
export const FETCH_PLACE_ALL_FULFILLED = 'FETCH_PLACE_ALL_FULFILLED';

export const FETCH_PLACE_LIST = 'FETCH_PLACE_LIST';
export const FETCH_PLACE_LIST_PENDING = 'FETCH_PLACE_LIST_PENDING';
export const FETCH_PLACE_LIST_REJECTED = 'FETCH_PLACE_LIST_REJECTED';
export const FETCH_PLACE_LIST_FULFILLED = 'FETCH_PLACE_LIST_FULFILLED';

export const PLACE_SET_EDIT_MODE = 'PLACE_SET_EDIT_MODE';
export const PLACE_FULL_DATA_ADD_OWNER = 'PLACE_FULL_DATA_ADD_OWNER';
export const PLACE_SET_BOOKMARK = 'PLACE_SET_BOOKMARK';
export const PLACE_SET_ACCESSILITY_TYPE = 'PLACE_SET_ACCESSILITY_TYPE';
export const PLACE_POINT_GET = 'PLACE_POINT_GET';
export const PLACE_SET_CATEGORY = 'PLACE_SET_CATEGORY';
export const PLACE_SET_ICON_TYPE = 'PLACE_SET_ICON_TYPE';
export const PLACE_SET_ICON_COLOR = 'PLACE_SET_ICON_COLOR';
export const PLACE_UPDATE_IMAGE = 'PLACE_UPDATE_IMAGE';
export const PLACE_SET_TITLE = 'PLACE_SET_TITLE:';
export const PLACE_SET_DESCRIPTION = 'PLACE_SET_DESCRIPTION';
export const PLACE_INITIAL_STATE = 'PLACE_INITIAL_STATE';
export const PLACE_TYPE_REQUIRED_FILED = 'PLACE_TYPE_REQUIRED_FILED';
export const PLACE_FULL_DATA_IMPORTED = 'PLACE_FULL_DATA_IMPORTED';
export const PLACE_FULL_SET_ACCESSILITY_TYPE =
  'PLACE_FULL_SET_ACCESSILITY_TYPE';
export const PLACE_ADD_ID_IN_CREATE_TOUR = 'PLACE_ADD_ID_IN_CREATE_TOUR';
export const PLACE_REMOVE_ID_IN_CREATE_TOUR = 'PLACE_REMOVE_ID_IN_CREATE_TOUR';

export const USER_PLACE_AMOUNT = 'USER_PLACE_AMOUNT';
export const USER_PLACE_AMOUNT_FULFILLED = 'USER_PLACE_AMOUNT_FULFILLED';

export const SET_CORRECTION_WRONG_ROUTE_STATUS =
  'SET_CORRECTION_WRONG_ROUTE_STATUS';
export const SET_AUTO_CORRECTION_TO_ROUTE_STATUS =
  'SET_AUTO_CORRECTION_TO_ROUTE_STATUS';
export const SET_VIA_CORRECTION_STATUS = 'SET_VIA_CORRECTION_STATUS';
export const SET_DRAG_CORRECTION_STATUS = 'SET_DRAG_CORRECTION_STATUS';
export const SET_OPTIMIZE_TOUR_SETTINGS = 'SET_OPTIMIZE_TOUR_SETTINGS';
export const SET_FULL_SCREEN_MODE_STATUS = 'SET_FULL_SCREEN_MODE_STATUS';

export const TOUR_FULL_DATA_AVOID_UNPAVED = 'TOUR_FULL_DATA_AVOID_UNPAVED';
export const TOUR_FULL_DATA_AVOID_HIGHWAY = 'TOUR_FULL_DATA_AVOID_HIGHWAY';
export const TOUR_FULL_DATA_AVOID_FERRY = 'TOUR_FULL_DATA_AVOID_FERRY';
export const TOUR_FULL_DATA_SWITCH_SHAPING_POINTS =
  'TOUR_FULL_DATA_SWITCH_SHAPING_POINTS';
export const TOUR_FULL_DATA_SWITCH_TTO_VISIBLE =
  'TOUR_FULL_DATA_SWITCH_TTO_VISIBLE';
export const TOUR_FULL_DATA_SWITCH_PLACE_DATA =
  'TOUR_FULL_DATA_SWITCH_PLACE_DATA';

// Folders
export const FOLDERS_GET = 'FOLDERS_GET';
export const FOLDERS_ADD = 'FOLDER_ADD';
export const FOLDERS_REMOVE = 'FOLDER_REMOVE';
export const FOLDERS_UPDATE = 'FOLDER_UPDATE';
export const FOLDERS_UPDATE_ALL = 'FOLDER_UPDATE_ALL';
export const FOLDERS_RESORT = 'FOLDERS_RESORT';

export const FOLDERS_PLACE_GET = 'FOLDERS_PLACE_GET';
export const FOLDERS_PLACE_ADD = 'FOLDER_PLACE_ADD';
export const FOLDERS_PLACE_REMOVE = 'FOLDER_PLACE_REMOVE';
export const FOLDERS_PLACE_UPDATE = 'FOLDER_PLACE_UPDATE';
export const FOLDERS_PLACE_UPDATE_ALL = 'FOLDER_PLACE_UPDATE_ALL';
export const FOLDERS_PLACE_RESORT = 'FOLDERS_PLACE_RESORT';

// Counter
export const counter = Object.freeze({
  setMyTours: 'COUNTER_SET_MY_TOURS',
  setMyPlaces: 'COUNTER_SET_MY_PLACES',
  increase: 'COUNTER_INCREASE',
  decrease: 'COUNTER_DECREASE',
});
