import * as types from '../actions/action_types';

const initialState = {
  collapse: true,
  folder_create_visible: false,
};

const mainMenuData = (state = initialState, action) => {
  switch (action.type) {
    case types.MAIN_MENU_COLLAPSE:
      return { ...state, collapse: action.payload };
    case types.SET_MENU_FOLDER_CREATE_VISIBLE:
      return { ...state, folder_create_visible: action.payload };
    default:
      return state;
  }
};
export default mainMenuData;
