import React from 'react';
import { Upload, message } from 'antd';

import { clubAPI } from 'app/API/club.api';

import withTranslations from 'app/components/HOGs/withTranslations';
import { ProfileMediaIcon } from '../../UserProfile/icons.svg';

const AddPictures = (props) => {
  const { tr, images, setImages, id, commentId, setCommentId } = props;

  return (
    <Upload
      action={clubAPI.uploadCommentClubPicture(id, commentId)}
      listType="picture-card"
      multiple={false}
      withCredentials
      showUploadList={{
        showDownloadIcon: false,
        showPreviewIcon: false,
        showRemoveIcon: false,
      }}
      className="add_image"
      onChange={(info) => {
        if (info.file.status === 'done') {
          setImages([...images, { ...info.file?.response.response }]);
          setCommentId(info.file?.response.response.id);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      }}
    >
      <div className="content">
        <ProfileMediaIcon />
        <span className="text">{tr['Add image']}</span>
      </div>
    </Upload>
  );
};

export default withTranslations(AddPictures);
