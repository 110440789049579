import React, { memo } from 'react';
import { useIntl } from 'react-intl';

const withTranslations = (WrappedComponent) => {
  return memo((props) => {
    const intl = useIntl();
    const translations = {
      Reset: intl.formatMessage({ id: 'Reset', defaultMessage: 'Reset' }),
      Undo: intl.formatMessage({ id: 'Undo', defaultMessage: 'Undo' }),
      Redo: intl.formatMessage({ id: 'Redo', defaultMessage: 'Redo' }),
      'Click to go back': intl.formatMessage({
        id: 'Click to go back',
        defaultMessage: 'Click to go back',
      }),
      'Click to go forward': intl.formatMessage({
        id: 'Click to go forward',
        defaultMessage: 'Click to go forward',
      }),
      Delete: intl.formatMessage({ id: 'Delete', defaultMessage: 'Delete' }),
      'Delete the tour?': intl.formatMessage({
        id: 'Delete the tour?',
        defaultMessage: 'Delete the tour?',
      }),
      'Clear the tour?': intl.formatMessage({
        id: 'Clear the tour?',
        defaultMessage: 'Clear the tour?',
      }),
      'Delete the place?': intl.formatMessage({
        id: 'Delete the place?',
        defaultMessage: 'Delete the place?',
      }),
      'Clear the Place': intl.formatMessage({
        id: 'Clear the Place',
        defaultMessage: 'Clear the Place',
      }),
      'No tour has been made': intl.formatMessage({
        id: 'No tour has been made',
        defaultMessage: 'No tour has been made',
      }),
      'To save this part of the tour you need to set at least a start and end point on the map.':
        intl.formatMessage({
          id: 'To save this part of the tour you need to set at least a start and end point on the map.',
          defaultMessage:
            'To save this part of the tour you need to set at least a start and end point on the map.',
        }),
      'Point address is loading': intl.formatMessage({
        id: 'Point address is loading',
        defaultMessage: 'Point address is loading',
      }),
      Edit: intl.formatMessage({ id: 'Edit', defaultMessage: 'Edit' }),
      'For me only': intl.formatMessage({
        id: 'For me only',
        defaultMessage: 'For me only',
      }),
      'My friends and me': intl.formatMessage({
        id: 'My friends and me',
        defaultMessage: 'My friends and me',
      }),
      'Select friends': intl.formatMessage({
        id: 'Select friends',
        defaultMessage: 'Select friends',
      }),
      'Add some friends so you can share the tour with them':
        intl.formatMessage({
          id: 'Add some friends so you can share the tour with them',
          defaultMessage:
            'Add some friends so you can share the tour with them',
        }),
      'All Tourstart users': intl.formatMessage({
        id: 'All Tourstart users',
        defaultMessage: 'All Tourstart users',
      }),
      'My Bike Club': intl.formatMessage({
        id: 'My Bike Club',
        defaultMessage: 'My Bike Club',
      }),
      'No BikeClub': intl.formatMessage({
        id: 'No BikeClub',
        defaultMessage: 'No BikeClub',
      }),
      'My BikerBed': intl.formatMessage({
        id: 'My BikerBed',
        defaultMessage: 'My BikerBed',
      }),
      'No BikerBed': intl.formatMessage({
        id: 'No BikerBed',
        defaultMessage: 'No BikerBed',
      }),
      Private: intl.formatMessage({ id: 'Private', defaultMessage: 'Private' }),
      Public: intl.formatMessage({ id: 'Public', defaultMessage: 'Public' }),
      'Import GPS file': intl.formatMessage({
        id: 'Import GPS file',
        defaultMessage: 'Import GPS file',
      }),
      'Public tour should have at least one picture': intl.formatMessage({
        id: 'Public tour should have at least one picture',
        defaultMessage: 'Public tour should have at least one picture',
      }),
      'Already added to download': intl.formatMessage({
        id: 'Already added to download',
        defaultMessage: 'Already added to download',
      }),
      'Save title': intl.formatMessage({
        id: 'Save title',
        defaultMessage: 'Save title',
      }),
      'Edit title': intl.formatMessage({
        id: 'Edit title',
        defaultMessage: 'Edit title',
      }),
      Drag: intl.formatMessage({ id: 'Drag', defaultMessage: 'Drag' }),
      All: intl.formatMessage({ id: 'All', defaultMessage: 'All' }),
      Part: intl.formatMessage({ id: 'Part', defaultMessage: 'Part' }),
      'Save and view tour': intl.formatMessage({
        id: 'Save and view tour',
        defaultMessage: 'Save and view tour',
      }),
      'Save and view place': intl.formatMessage({
        id: 'Save and view place',
        defaultMessage: 'Save and view place',
      }),
      'Optimize all': intl.formatMessage({
        id: 'Optimize all',
        defaultMessage: 'Optimize all',
      }),
      'Correct via point': intl.formatMessage({
        id: 'Correct via point',
        defaultMessage: 'Correct via point',
      }),
      'Correct drag point': intl.formatMessage({
        id: 'Correct drag point',
        defaultMessage: 'Correct drag point',
      }),
      'See in': intl.formatMessage({ id: 'See in', defaultMessage: 'See in' }),
      'more details': intl.formatMessage({
        id: 'more details',
        defaultMessage: 'more details',
      }),
      Help: intl.formatMessage({ id: 'Help', defaultMessage: 'Help' }),
      'Show shaping points': intl.formatMessage({
        id: 'Show shaping points',
        defaultMessage: 'Show shaping points',
      }),
      'Auto drag to route': intl.formatMessage({
        id: 'Auto drag to route',
        defaultMessage: 'Auto drag to route',
      }),
      'Route options': intl.formatMessage({
        id: 'Route options',
        defaultMessage: 'Route options',
      }),
      'Route shaping points': intl.formatMessage({
        id: 'Route shaping points',
        defaultMessage: 'Route shaping points',
      }),
      'Point optimization': intl.formatMessage({
        id: 'Point optimization',
        defaultMessage: 'Point optimization',
      }),
      'Highlight unpaved roads': intl.formatMessage({
        id: 'Highlight unpaved roads',
        defaultMessage: 'Highlight unpaved roads',
      }),
      'Avoid highway': intl.formatMessage({
        id: 'Avoid highway',
        defaultMessage: 'Avoid highway',
      }),
      'Avoid Ferry': intl.formatMessage({
        id: 'Avoid Ferry',
        defaultMessage: 'Avoid Ferry',
      }),
      Saving: intl.formatMessage({ id: 'Saving', defaultMessage: 'Saving' }),
      'Changes may not be saved': intl.formatMessage({
        id: 'Changes may not be saved',
        defaultMessage: 'Changes may not be saved',
      }),
      'Login to view this tour': intl.formatMessage({
        id: 'Login to view this tour',
        defaultMessage: 'Login to view this tour',
      }),
      'Add additional part to the tour': intl.formatMessage({
        id: 'Add additional part to the tour',
        defaultMessage: 'Add additional part to the tour',
      }),
      'Title of the tour is missing': intl.formatMessage({
        id: 'Title of the tour is missing',
        defaultMessage: 'Title of the tour is missing',
      }),
      'Tour should have at least one point': intl.formatMessage({
        id: 'Tour should have at least one point',
        defaultMessage: 'Tour should have at least one point',
      }),
      'file uploaded successfully': intl.formatMessage({
        id: 'file uploaded successfully',
        defaultMessage: 'file uploaded successfully',
      }),
      'file upload failed': intl.formatMessage({
        id: 'file upload failed',
        defaultMessage: 'file upload failed',
      }),
      'Click or drag file to this area to upload': intl.formatMessage({
        id: 'Click or drag file to this area to upload',
        defaultMessage: 'Click or drag file to this area to upload',
      }),
      'Time and distance between points': intl.formatMessage({
        id: 'Time and distance between points',
        defaultMessage: 'Time and distance between points',
      }),
      km: intl.formatMessage({ id: 'km', defaultMessage: 'km' }),
      m: intl.formatMessage({ id: 'm', defaultMessage: 'm' }),
      h: intl.formatMessage({ id: 'h', defaultMessage: 'h' }),
      min: intl.formatMessage({ id: 'min', defaultMessage: 'min' }),
      kilometers: intl.formatMessage({
        id: 'kilometers',
        defaultMessage: 'kilometers',
      }),
      miles: intl.formatMessage({ id: 'miles', defaultMessage: 'miles' }),
      Start: intl.formatMessage({ id: 'Start', defaultMessage: 'Start' }),
      'Time and distance - accumulated': intl.formatMessage({
        id: 'Time and distance - accumulated',
        defaultMessage: 'Time and distance - accumulated',
      }),
      Description: intl.formatMessage({
        id: 'Description',
        defaultMessage: 'Description',
      }),
      'Name of point': intl.formatMessage({
        id: 'Name of point',
        defaultMessage: 'Name of point',
      }),
      'Add point to download': intl.formatMessage({
        id: 'Add point to download',
        defaultMessage: 'Add point to download',
      }),
      'Show the entire Tour on the map': intl.formatMessage({
        id: 'Show the entire Tour on the map',
        defaultMessage: 'Show the entire Tour on the map',
      }),
      'Download PDF': intl.formatMessage({
        id: 'Download PDF',
        defaultMessage: 'Download PDF',
      }),
      'Reverse tour': intl.formatMessage({
        id: 'Reverse tour',
        defaultMessage: 'Reverse tour',
      }),
      'Full map view': intl.formatMessage({
        id: 'Full map view',
        defaultMessage: 'Full map view',
      }),
      'Set your HOME position': intl.formatMessage({
        id: 'Set your HOME position',
        defaultMessage: 'Set your HOME position',
      }),
      'Use home position as start point': intl.formatMessage({
        id: 'Use home position as start point',
        defaultMessage: 'Use home position as start point',
      }),
      'Use home position as end point': intl.formatMessage({
        id: 'Use home position as end point',
        defaultMessage: 'Use home position as end point',
      }),
      'Use work position as start point': intl.formatMessage({
        id: 'Use work position as start point',
        defaultMessage: 'Use work position as start point',
      }),
      'Use work position as end point': intl.formatMessage({
        id: 'Use work position as end point',
        defaultMessage: 'Use work position as end point',
      }),
      'Set HOME address': intl.formatMessage({
        id: 'Set HOME address',
        defaultMessage: 'Set HOME address',
      }),
      Searching: intl.formatMessage({
        id: 'Searching',
        defaultMessage: 'Searching',
      }),
      'Tour description': intl.formatMessage({
        id: 'Tour description',
        defaultMessage: 'Tour description',
      }),
      'Add via point': intl.formatMessage({
        id: 'Add via point',
        defaultMessage: 'Add via point',
      }),
      'Click red symbol to check': intl.formatMessage({
        id: 'Click red symbol to check',
        defaultMessage: 'Click red symbol to check',
      }),
      "There aren't points in the loaded GPS data": intl.formatMessage({
        id: "There aren't points in the loaded GPS data",
        defaultMessage: "There aren't points in the loaded GPS data",
      }),
      'file upload failed.': intl.formatMessage({
        id: 'file upload failed.',
        defaultMessage: 'file upload failed.',
      }),
      'No Image': intl.formatMessage({
        id: 'No Image',
        defaultMessage: 'No Image',
      }),
      by: intl.formatMessage({
        id: 'by',
        defaultMessage: 'by',
      }),
      'Tourstart Tour Optimizer': intl.formatMessage({
        id: 'Tourstart Tour Optimizer',
        defaultMessage: 'Tourstart Tour Optimizer',
      }),
      'Plan in Create Tour': intl.formatMessage({
        id: 'Plan in Create Tour',
        defaultMessage: 'Plan in Create Tour',
      }),
      'Add to download': intl.formatMessage({
        id: 'Add to download',
        defaultMessage: 'Add to download',
      }),
      'Set address': intl.formatMessage({
        id: 'Set address',
        defaultMessage: 'Set address',
      }),
      Home: intl.formatMessage({
        id: 'Home',
        defaultMessage: 'Home',
      }),
      Work: intl.formatMessage({
        id: 'Work',
        defaultMessage: 'Work',
      }),
      'Home Address': intl.formatMessage({
        id: 'Home Address',
        defaultMessage: 'Home Address',
      }),
      'Work Address': intl.formatMessage({
        id: 'Work Address',
        defaultMessage: 'Work Address',
      }),
      'Current position': intl.formatMessage({
        id: 'Current position',
        defaultMessage: 'Current position',
      }),
      'Start point': intl.formatMessage({
        id: 'Start point',
        defaultMessage: 'Start point',
      }),
      Custom: intl.formatMessage({ id: 'Custom', defaultMessage: 'Custom' }),
      'Set the start position on the map': intl.formatMessage({
        id: 'Set the start position on the map',
        defaultMessage: 'Set the start position on the map',
      }),
      'Log in to use your home position or set it directly on the map':
        intl.formatMessage({
          id: 'Log in to use your home position or set it directly on the map',
          defaultMessage:
            'Log in to use your home position or set it directly on the map',
        }),
      'Set home position to use it as a start point or press the button to set the start point':
        intl.formatMessage({
          id: 'Set home position to use it as a start point or press the button to set the start point',
          defaultMessage:
            'Set home position to use it as a start point or press the button to set the start point',
        }),
      'Not set yet': intl.formatMessage({
        id: 'Not set yet',
        defaultMessage: 'Not set yet',
      }),
      'Your current tour will be cleared if you continue. Do you want to continue?':
        intl.formatMessage({
          id: 'Your current tour will be cleared if you continue. Do you want to continue?',
          defaultMessage:
            'Your current tour will be cleared if you continue. Do you want to continue?',
        }),
      'Tourstart RideTwister': intl.formatMessage({
        id: 'Tourstart RideTwister',
        defaultMessage: 'Tourstart RideTwister',
      }),
      'Finger Draw': intl.formatMessage({
        id: 'Finger Draw',
        defaultMessage: 'Finger Draw',
      }),
      Destination: intl.formatMessage({
        id: 'Destination',
        defaultMessage: 'Destination',
      }),
      'Round Trip': intl.formatMessage({
        id: 'Round Trip',
        defaultMessage: 'Round Trip',
      }),
      Poi: intl.formatMessage({
        id: 'Poi',
        defaultMessage: 'Poi',
      }),
      Pro: intl.formatMessage({
        id: 'Pro',
        defaultMessage: 'Pro',
      }),
      Ok: intl.formatMessage({
        id: 'Ok',
        defaultMessage: 'Ok',
      }),
      'We are sorry that you have chosen to unsubscribe, and we hope to see you back soon again':
        intl.formatMessage({
          id: 'We are sorry that you have chosen to unsubscribe, and we hope to see you back soon again',
          defaultMessage:
            'We are sorry that you have chosen to unsubscribe, and we hope to see you back soon again',
        }),
      Price: intl.formatMessage({ id: 'Price', defaultMessage: 'Price' }),
      'You are a Pro motorcycle rider': intl.formatMessage({
        id: 'You are a Pro motorcycle rider',
        defaultMessage: 'You are a Pro motorcycle rider',
      }),
      'You are Free Rider': intl.formatMessage({
        id: 'You are Free Rider',
        defaultMessage: 'You are Free Rider',
      }),
      'Remaining days before renewal': intl.formatMessage({
        id: 'Remaining days before renewal',
        defaultMessage: 'Remaining days before renewal',
      }),
      'You have automatic renewal': intl.formatMessage({
        id: 'You have automatic renewal',
        defaultMessage: 'You have automatic renewal',
      }),
      'You do NOT have automatic renewal': intl.formatMessage({
        id: 'You do NOT have automatic renewal',
        defaultMessage: 'You do NOT have automatic renewal',
      }),
      'View and download my invoices': intl.formatMessage({
        id: 'View and download my invoices',
        defaultMessage: 'View and download my invoices',
      }),
      Voucher: intl.formatMessage({ id: 'Voucher', defaultMessage: 'Voucher' }),
      Total: intl.formatMessage({ id: 'Total', defaultMessage: 'Total' }),
      'All-In-One includes': intl.formatMessage({
        id: 'All-In-One includes',
        defaultMessage: 'All-In-One includes',
      }),
      'Transfer and download GPS data': intl.formatMessage({
        id: 'Transfer and download GPS data',
        defaultMessage: 'Transfer and download GPS data',
      }),
      'iPhone navigation access': intl.formatMessage({
        id: 'iPhone navigation access',
        defaultMessage: 'iPhone navigation access',
      }),
      'Android navigation access': intl.formatMessage({
        id: 'Android navigation access',
        defaultMessage: 'Android navigation access',
      }),
      'Order overview': intl.formatMessage({
        id: 'Order overview',
        defaultMessage: 'Order overview',
      }),
      Purchase: intl.formatMessage({
        id: 'Purchase',
        defaultMessage: 'Purchase',
      }),
      'Automatic renewal of the subscription': intl.formatMessage({
        id: 'Automatic renewal of the subscription',
        defaultMessage: 'Automatic renewal of the subscription',
      }),
      'I accept the': intl.formatMessage({
        id: 'I accept the',
        defaultMessage: 'I accept the',
      }),
      'purchase conditions': intl.formatMessage({
        id: 'purchase conditions',
        defaultMessage: 'purchase conditions',
      }),
      link: intl.formatMessage({ id: 'link', defaultMessage: 'link' }),
      'Voucher value': intl.formatMessage({
        id: 'Voucher value',
        defaultMessage: 'Voucher value',
      }),
      Currency: intl.formatMessage({
        id: 'Currency',
        defaultMessage: 'Currency',
      }),
      'Voucher code': intl.formatMessage({
        id: 'Voucher code',
        defaultMessage: 'Voucher code',
      }),
      'Discount code': intl.formatMessage({
        id: 'Discount code',
        defaultMessage: 'Discount code',
      }),
      'incl VAT': intl.formatMessage({
        id: 'incl VAT',
        defaultMessage: 'incl VAT',
      }),
      'Set start point': intl.formatMessage({
        id: 'Set start point',
        defaultMessage: 'Set start point',
      }),
      'Set Start point': intl.formatMessage({
        id: 'Set Start point',
        defaultMessage: 'Set Start point',
      }),
      'Set POI': intl.formatMessage({
        id: 'Set POI',
        defaultMessage: 'Set POI',
      }),
      'Set end point': intl.formatMessage({
        id: 'Set end point',
        defaultMessage: 'Set end point',
      }),
      'Set End point': intl.formatMessage({
        id: 'Set End point',
        defaultMessage: 'Set End point',
      }),

      'Tour start here': intl.formatMessage({
        id: 'Tour start here',
        defaultMessage: 'Tour start here',
      }),
      'Tour end here': intl.formatMessage({
        id: 'Tour end here',
        defaultMessage: 'Tour end here',
      }),
      'Add to my place': intl.formatMessage({
        id: 'Add to my place',
        defaultMessage: 'Add to my place',
      }),
      'Remove marker': intl.formatMessage({
        id: 'Remove marker',
        defaultMessage: 'Remove marker',
      }),
      'Recent search': intl.formatMessage({
        id: 'Recent search',
        defaultMessage: 'Recent search',
      }),
      'Related search': intl.formatMessage({
        id: 'Related search',
        defaultMessage: 'Related search',
      }),
      'Required fields are not filled in': intl.formatMessage({
        id: 'Required fields are not filled in',
        defaultMessage: 'Required fields are not filled in',
      }),
      'Website url is not valid': intl.formatMessage({
        id: 'Website url is not valid',
        defaultMessage: 'Website url is not valid',
      }),
      About: intl.formatMessage({ id: 'About', defaultMessage: 'About' }),
      'Write facts about the BikerBed': intl.formatMessage({
        id: 'Write facts about the BikerBed',
        defaultMessage: 'Write facts about the BikerBed',
      }),
      Contact: intl.formatMessage({ id: 'Contact', defaultMessage: 'Contact' }),
      'Write address': intl.formatMessage({
        id: 'Write address',
        defaultMessage: 'Write address',
      }),
      'Click on map to set address': intl.formatMessage({
        id: 'Click on map to set address',
        defaultMessage: 'Click on map to set address',
      }),
      'Link to your web site': intl.formatMessage({
        id: 'Link to your web site',
        defaultMessage: 'Link to your web site',
      }),
      'Additional contact information': intl.formatMessage({
        id: 'Additional contact information',
        defaultMessage: 'Additional contact information',
      }),
      Pictures: intl.formatMessage({
        id: 'Pictures',
        defaultMessage: 'Pictures',
      }),
      Upload: intl.formatMessage({ id: 'Upload', defaultMessage: 'Upload' }),
      Videos: intl.formatMessage({ id: 'Videos', defaultMessage: 'Videos' }),
      Services: intl.formatMessage({
        id: 'Services',
        defaultMessage: 'Services',
      }),
      'Your changes are saved': intl.formatMessage({
        id: 'Your changes are saved',
        defaultMessage: 'Your changes are saved',
      }),
      'Do you want to discard changes?': intl.formatMessage({
        id: 'Do you want to discard changes?',
        defaultMessage: 'Do you want to discard changes?',
      }),
      'Discard changes': intl.formatMessage({
        id: 'Discard changes',
        defaultMessage: 'Discard changes',
      }),
      'Save changes': intl.formatMessage({
        id: 'Save changes',
        defaultMessage: 'Save changes',
      }),
      Create: intl.formatMessage({ id: 'Create', defaultMessage: 'Create' }),
      'Enter current password': intl.formatMessage({
        id: 'Enter current password',
        defaultMessage: 'Enter current password',
      }),
      'Write your password!': intl.formatMessage({
        id: 'Write your password!',
        defaultMessage: 'Write your password!',
      }),
      'Confirm new password': intl.formatMessage({
        id: 'Confirm new password',
        defaultMessage: 'Confirm new password',
      }),
      'Enter new password': intl.formatMessage({
        id: 'Enter new password',
        defaultMessage: 'Enter new password',
      }),
      'Passwords do not match': intl.formatMessage({
        id: 'Passwords do not match',
        defaultMessage: 'Passwords do not match',
      }),
      Change: intl.formatMessage({ id: 'Change', defaultMessage: 'Change' }),
      Save: intl.formatMessage({ id: 'Save', defaultMessage: 'Save' }),
      Cancel: intl.formatMessage({ id: 'Cancel', defaultMessage: 'Cancel' }),
      'Change password': intl.formatMessage({
        id: 'Change password',
        defaultMessage: 'Change password',
      }),
      Password: intl.formatMessage({
        id: 'Password',
        defaultMessage: 'Password',
      }),
      'E-mail addresses do not match': intl.formatMessage({
        id: 'E-mail addresses do not match',
        defaultMessage: 'E-mail addresses do not match',
      }),
      'Write email': intl.formatMessage({
        id: 'Write email',
        defaultMessage: 'Write email',
      }),
      'Change email': intl.formatMessage({
        id: 'Change email',
        defaultMessage: 'Change email',
      }),
      'E-mail is not valid': intl.formatMessage({
        id: 'E-mail is not valid',
        defaultMessage: 'E-mail is not valid',
      }),
      'Write your e-mail': intl.formatMessage({
        id: 'Write your e-mail',
        defaultMessage: 'Write your e-mail',
      }),
      'Enter new e-mail': intl.formatMessage({
        id: 'Enter new e-mail',
        defaultMessage: 'Enter new e-mail',
      }),
      'Confirm new e-mail': intl.formatMessage({
        id: 'Confirm new e-mail',
        defaultMessage: 'Confirm new e-mail',
      }),
      Success: intl.formatMessage({ id: 'Success', defaultMessage: 'Success' }),
      Send: intl.formatMessage({ id: 'Send', defaultMessage: 'Send' }),
      'Write to us': intl.formatMessage({
        id: 'Write to us',
        defaultMessage: 'Write to us',
      }),
      'Write your name': intl.formatMessage({
        id: 'Write your name',
        defaultMessage: 'Write your name',
      }),
      'Your name': intl.formatMessage({
        id: 'Your name',
        defaultMessage: 'Your name',
      }),
      'E-mail': intl.formatMessage({ id: 'E-mail', defaultMessage: 'E-mail' }),
      'Your e-mail': intl.formatMessage({
        id: 'Your e-mail',
        defaultMessage: 'Your e-mail',
      }),
      Message: intl.formatMessage({ id: 'Message', defaultMessage: 'Message' }),
      'Write your message': intl.formatMessage({
        id: 'Write your message',
        defaultMessage: 'Write your message',
      }),
      'Your message': intl.formatMessage({
        id: 'Your message',
        defaultMessage: 'Your message',
      }),
      'Paste the code or link': intl.formatMessage({
        id: 'Paste the code or link',
        defaultMessage: 'Paste the code or link',
      }),
      'Registration code is being used': intl.formatMessage({
        id: 'Registration code is being used',
        defaultMessage: 'Registration code is being used',
      }),
      Register: intl.formatMessage({
        id: 'Register',
        defaultMessage: 'Register',
      }),
      'Your alias': intl.formatMessage({
        id: 'Your alias',
        defaultMessage: 'Your alias',
      }),
      'Remember me': intl.formatMessage({
        id: 'Remember me',
        defaultMessage: 'Remember me',
      }),
      'Pro registration': intl.formatMessage({
        id: 'Pro registration',
        defaultMessage: 'Pro registration',
      }),
      'New password': intl.formatMessage({
        id: 'New password',
        defaultMessage: 'New password',
      }),
      Update: intl.formatMessage({ id: 'Update', defaultMessage: 'Update' }),
      'Password was renewed': intl.formatMessage({
        id: 'Password was renewed',
        defaultMessage: 'Password was renewed',
      }),
      Feedback: intl.formatMessage({
        id: 'Feedback',
        defaultMessage: 'Feedback',
      }),
      Name: intl.formatMessage({ id: 'Name', defaultMessage: 'Name' }),
      'Write facts about the bike club': intl.formatMessage({
        id: 'Write facts about the bike club',
        defaultMessage: 'Write facts about the bike club',
      }),
      'Bikerclub does not exist or is not active': intl.formatMessage({
        id: 'Bikerclub does not exist or is not active',
        defaultMessage: 'Bikerclub does not exist or is not active',
      }),
      VIEWS: intl.formatMessage({ id: 'VIEWS', defaultMessage: 'VIEWS' }),
      TOURS: intl.formatMessage({ id: 'TOURS', defaultMessage: 'TOURS' }),
      DOWNLOADS: intl.formatMessage({
        id: 'DOWNLOADS',
        defaultMessage: 'DOWNLOADS',
      }),
      'Name of BikerBed': intl.formatMessage({
        id: 'Name of BikerBed',
        defaultMessage: 'Name of BikerBed',
      }),
      'Created by': intl.formatMessage({
        id: 'Created by',
        defaultMessage: 'Created by',
      }),
      'Select country': intl.formatMessage({
        id: 'Select country',
        defaultMessage: 'Select country',
      }),
      'Write your password': intl.formatMessage({
        id: 'Write your password',
        defaultMessage: 'Write your password',
      }),
      'Confirm your password': intl.formatMessage({
        id: 'Confirm your password',
        defaultMessage: 'Confirm your password',
      }),
      'Please input your unique alias': intl.formatMessage({
        id: 'Please input your unique alias',
        defaultMessage: 'Please input your unique alias',
      }),
      'Agree on the legal issues': intl.formatMessage({
        id: 'Agree on the legal issues',
        defaultMessage: 'Agree on the legal issues',
      }),
      'Link to Legal Issues': intl.formatMessage({
        id: 'Link to Legal Issues',
        defaultMessage: 'Link to Legal Issues',
      }),
      'I agree to all the Legal Issues and accept that I have read, understood and accepted these which can be read on this link':
        intl.formatMessage({
          id: 'I agree to all the Legal Issues and accept that I have read, understood and accepted these which can be read on this link',
          defaultMessage:
            'I agree to all the Legal Issues and accept that I have read, understood and accepted these which can be read on this link',
        }),
      'Create folder': intl.formatMessage({
        id: 'Create folder',
        defaultMessage: 'Create folder',
      }),
      'App Store': intl.formatMessage({
        id: 'App Store',
        defaultMessage: 'App Store',
      }),
      'Google Play': intl.formatMessage({
        id: 'Google Play',
        defaultMessage: 'Google Play',
      }),
      DK: intl.formatMessage({ id: 'DK', defaultMessage: 'DK' }),
      ENGLISH: intl.formatMessage({ id: 'ENGLISH', defaultMessage: 'ENGLISH' }),
      Rename: intl.formatMessage({ id: 'Rename', defaultMessage: 'Rename' }),
      Remove: intl.formatMessage({ id: 'Remove', defaultMessage: 'Remove' }),
      'Do you want to delete this folder': intl.formatMessage({
        id: 'Do you want to delete this folder',
        defaultMessage: 'Do you want to delete this folder',
      }),
      'Tours will not be removed': intl.formatMessage({
        id: 'Tours will not be removed',
        defaultMessage: 'Tours will not be removed',
      }),
      Yes: intl.formatMessage({ id: 'Yes', defaultMessage: 'Yes' }),
      No: intl.formatMessage({ id: 'No', defaultMessage: 'No' }),
      'Number of tours': intl.formatMessage({
        id: 'Number of tours',
        defaultMessage: 'Number of tours',
      }),
      'For transfer a route to your GPS device, follow one of these methods':
        intl.formatMessage({
          id: 'For transfer a route to your GPS device, follow one of these methods',
          defaultMessage:
            'For transfer a route to your GPS device, follow one of these methods',
        }),
      'The first method': intl.formatMessage({
        id: 'The first method',
        defaultMessage: 'The first method',
      }),
      'Open Tourstart Transfer': intl.formatMessage({
        id: 'Open Tourstart Transfer',
        defaultMessage: 'Open Tourstart Transfer',
      }),
      'Open the route which you want to transfer': intl.formatMessage({
        id: 'Open the route which you want to transfer',
        defaultMessage: 'Open the route which you want to transfer',
      }),
      'Connect the GPS to the computer and wait for Tourstart Transfer to detect it is connected':
        intl.formatMessage({
          id: 'Connect the GPS to the computer and wait for Tourstart Transfer to detect it is connected',
          defaultMessage:
            'Connect the GPS to the computer and wait for Tourstart Transfer to detect it is connected',
        }),
      "When the button 'Transfer route to GPS' becomes active - press this button - and the route is transferred to the GPS":
        intl.formatMessage({
          id: "When the button 'Transfer route to GPS' becomes active - press this button - and the route is transferred to the GPS",
          defaultMessage:
            "When the button 'Transfer route to GPS' becomes active - press this button - and the route is transferred to the GPS",
        }),
      'The second method': intl.formatMessage({
        id: 'The second method',
        defaultMessage: 'The second method',
      }),
      'Start Tourstart Transfer by clicking on the desired route you want to transfer to the GPS':
        intl.formatMessage({
          id: 'Start Tourstart Transfer by clicking on the desired route you want to transfer to the GPS',
          defaultMessage:
            'Start Tourstart Transfer by clicking on the desired route you want to transfer to the GPS',
        }),
      'The GPS is not recognized': intl.formatMessage({
        id: 'The GPS is not recognized',
        defaultMessage: 'The GPS is not recognized',
      }),
      'To add the GPS to the list of devices, follow these steps':
        intl.formatMessage({
          id: 'To add the GPS to the list of devices, follow these steps',
          defaultMessage:
            'To add the GPS to the list of devices, follow these steps',
        }),
      "Click 'OK' to send information to Tourstart": intl.formatMessage({
        id: "Click 'OK' to send information to Tourstart",
        defaultMessage: "Click 'OK' to send information to Tourstart",
      }),
      'A pop-up is shown in which you can select a GPS from the list of connected devices':
        intl.formatMessage({
          id: 'A pop-up is shown in which you can select a GPS from the list of connected devices',
          defaultMessage:
            'A pop-up is shown in which you can select a GPS from the list of connected devices',
        }),
      'Connect the GPS to the computer and wait for the GPS to be detected':
        intl.formatMessage({
          id: 'Connect the GPS to the computer and wait for the GPS to be detected',
          defaultMessage:
            'Connect the GPS to the computer and wait for the GPS to be detected',
        }),
      'Write to info@tourstart_org for support': intl.formatMessage({
        id: 'Write to info@tourstart_org for support',
        defaultMessage: 'Write to info@tourstart_org for support',
      }),
      'If your GPS is not detected, you can check in your file browser if the GPS is connected':
        intl.formatMessage({
          id: 'If your GPS is not detected, you can check in your file browser if the GPS is connected',
          defaultMessage:
            'If your GPS is not detected, you can check in your file browser if the GPS is connected',
        }),
      'Wait for Tourstart Transfer to detect your GPS': intl.formatMessage({
        id: 'Wait for Tourstart Transfer to detect your GPS',
        defaultMessage: 'Wait for Tourstart Transfer to detect your GPS',
      }),
      'Make sure the GPS is connected to the computer': intl.formatMessage({
        id: 'Make sure the GPS is connected to the computer',
        defaultMessage: 'Make sure the GPS is connected to the computer',
      }),
      View: intl.formatMessage({ id: 'View', defaultMessage: 'View' }),
      'Remove this from the list?': intl.formatMessage({
        id: 'Remove this from the list?',
        defaultMessage: 'Remove this from the list?',
      }),
      'Open in app': intl.formatMessage({
        id: 'Open in app',
        defaultMessage: 'Open in app',
      }),
      'Sign in': intl.formatMessage({
        id: 'Sign in',
        defaultMessage: 'Sign in',
      }),
      'Name of motorcycle club': intl.formatMessage({
        id: 'Name of motorcycle club',
        defaultMessage: 'Name of motorcycle club',
      }),
      On: intl.formatMessage({ id: 'On', defaultMessage: 'On' }),
      Off: intl.formatMessage({ id: 'Off', defaultMessage: 'Off' }),
      '14 days free trial for new users': intl.formatMessage({
        id: '14 days free trial for new users',
        defaultMessage: '14 days free trial for new users',
      }),
      'Enter Password': intl.formatMessage({
        id: 'Enter Password',
        defaultMessage: 'Enter Password',
      }),
      'Confirm password': intl.formatMessage({
        id: 'Confirm password',
        defaultMessage: 'Confirm password',
      }),
      'Accept the purchase conditions': intl.formatMessage({
        id: 'Accept the purchase conditions',
        defaultMessage: 'Accept the purchase conditions',
      }),
      'Delete my account ...': intl.formatMessage({
        id: 'Delete my account ...',
        defaultMessage: 'Delete my account ...',
      }),
      'Account Deletion': intl.formatMessage({
        id: 'Account Deletion',
        defaultMessage: 'Account Deletion',
      }),
      'Not found': intl.formatMessage({
        id: 'Not found',
        defaultMessage: 'Not found',
      }),
      'Something went wrong': intl.formatMessage({
        id: 'Something went wrong',
        defaultMessage: 'Something went wrong',
      }),
      'Headline (optionally)': intl.formatMessage({
        id: 'Headline (optionally)',
        defaultMessage: 'Headline (optionally)',
      }),
      Headline: intl.formatMessage({
        id: 'Headline',
        defaultMessage: 'Headline',
      }),
      'Same message to all users': intl.formatMessage({
        id: 'Same message to all users',
        defaultMessage: 'Same message to all users',
      }),
      'Translate to all languages': intl.formatMessage({
        id: 'Translate to all languages',
        defaultMessage: 'Translate to all languages',
      }),
      'Do not send to iPhone/Android': intl.formatMessage({
        id: 'Do not send to iPhone/Android',
        defaultMessage: 'Do not send to iPhone/Android',
      }),
      Publish: intl.formatMessage({ id: 'Publish', defaultMessage: 'Publish' }),
      'What is on your mind?': intl.formatMessage({
        id: 'What is on your mind?',
        defaultMessage: 'What is on your mind?',
      }),
      'Add image': intl.formatMessage({
        id: 'Add image',
        defaultMessage: 'Add image',
      }),
      SEARCH: intl.formatMessage({ id: 'SEARCH', defaultMessage: 'SEARCH' }),
      'Public Places': intl.formatMessage({
        id: 'Public Places',
        defaultMessage: 'Public Places',
      }),
      'Zoom out to see tours': intl.formatMessage({
        id: 'Zoom out to see tours',
        defaultMessage: 'Zoom out to see tours',
      }),
      'My Places': intl.formatMessage({
        id: 'My Places',
        defaultMessage: 'My Places',
      }),
      'Place must have address': intl.formatMessage({
        id: 'Place must have address',
        defaultMessage: 'Place must have address',
      }),
      'Number of Place': intl.formatMessage({
        id: 'Number of Place',
        defaultMessage: 'Number of Place',
      }),
      'Login to see your tours': intl.formatMessage({
        id: 'Login to see your tours',
        defaultMessage: 'Login to see your tours',
      }),
      My: intl.formatMessage({
        id: 'My',
        defaultMessage: 'My',
      }),
      'Description of the Place': intl.formatMessage({
        id: 'Description of the Place',
        defaultMessage: 'Description of the Place',
      }),
      'Write place address': intl.formatMessage({
        id: 'Write place address',
        defaultMessage: 'Write place address',
      }),
      'No address found': intl.formatMessage({
        id: 'No address found',
        defaultMessage: 'No address found',
      }),
      'Login to see your folders': intl.formatMessage({
        id: 'Login to see your folders',
        defaultMessage: 'Login to see your folders',
      }),
      'No folder': intl.formatMessage({
        id: 'No folder',
        defaultMessage: 'No folder',
      }),
      Places: intl.formatMessage({ id: 'Places', defaultMessage: 'Places' }),
      'Show Places': intl.formatMessage({
        id: 'Show Places',
        defaultMessage: 'Show Places',
      }),
      'Create place': intl.formatMessage({
        id: 'Create place',
        defaultMessage: 'Create place',
      }),
      'Create tour': intl.formatMessage({
        id: 'Create tour',
        defaultMessage: 'Create tour',
      }),
      'Create a Place': intl.formatMessage({
        id: 'Create a Place',
        defaultMessage: 'Create a Place',
      }),
      'Your current part will be cleared if you continue. Do you want to continue?':
        intl.formatMessage({
          id: 'Your current part will be cleared if you continue. Do you want to continue?',
          defaultMessage:
            'Your current part will be cleared if you continue. Do you want to continue?',
        }),
      'Login to see your places': intl.formatMessage({
        id: 'Login to see your places',
        defaultMessage: 'Login to see your places',
      }),
      'Write address(create tour)': intl.formatMessage({
        id: 'Write address(create tour)',
        defaultMessage: `Write point address`,
      }),
      'All-In-One': intl.formatMessage({
        id: 'All-In-One',
        defaultMessage: 'All-In-One',
      }),
      'Motorcycle Ride Planning': intl.formatMessage({
        id: 'Motorcycle Ride Planning',
        defaultMessage: 'Motorcycle Ride Planning',
      }),
      'WEB - APP - TRANSFER TO GPS': intl.formatMessage({
        id: 'WEB - APP - TRANSFER TO GPS',
        defaultMessage: 'WEB - APP - TRANSFER TO GPS',
      }),
      'motorcycle rides': intl.formatMessage({
        id: 'motorcycle rides',
        defaultMessage: 'motorcycle rides',
      }),
      'of riding': intl.formatMessage({
        id: 'of riding',
        defaultMessage: 'of riding',
      }),
      'tracked tours': intl.formatMessage({
        id: 'tracked tours',
        defaultMessage: 'tracked tours',
      }),
      'tours planned on Tourstart': intl.formatMessage({
        id: 'tours planned on Tourstart',
        defaultMessage: 'tours planned on Tourstart',
      }),
      Connect: intl.formatMessage({ id: 'Connect', defaultMessage: 'Connect' }),
      Friends: intl.formatMessage({ id: 'Friends', defaultMessage: 'Friends' }),
      Members: intl.formatMessage({ id: 'Members', defaultMessage: 'Members' }),
      PICTURES: intl.formatMessage({
        id: 'PICTURES',
        defaultMessage: 'PICTURES',
      }),
      VIDEOS: intl.formatMessage({ id: 'VIDEOS', defaultMessage: 'VIDEOS' }),
      'Bike club friends': intl.formatMessage({
        id: 'Bike club friends',
        defaultMessage: 'Bike club friends',
      }),
      'BikerBed friends': intl.formatMessage({
        id: 'BikerBed friends',
        defaultMessage: 'BikerBed friends',
      }),
      'Member since': intl.formatMessage({
        id: 'Member since',
        defaultMessage: 'Member since',
      }),
      'Remove friendship': intl.formatMessage({
        id: 'Remove friendship',
        defaultMessage: 'Remove friendship',
      }),
      'Cancel request': intl.formatMessage({
        id: 'Cancel request',
        defaultMessage: 'Cancel request',
      }),
      'Your profile': intl.formatMessage({
        id: 'Your profile',
        defaultMessage: 'Your profile',
      }),
      Cancelled: intl.formatMessage({
        id: 'Cancelled',
        defaultMessage: 'Cancelled',
      }),
      Sent: intl.formatMessage({ id: 'Sent', defaultMessage: 'Sent' }),
      'Bike Club Deletion': intl.formatMessage({
        id: 'Bike Club Deletion',
        defaultMessage: 'Bike Club Deletion',
      }),
      'BikerBed Deletion': intl.formatMessage({
        id: 'BikerBed Deletion',
        defaultMessage: 'BikerBed Deletion',
      }),
      'Delete bike club ...': intl.formatMessage({
        id: 'Delete bike club ...',
        defaultMessage: 'Delete bike club ...',
      }),
      'Delete bikerbed ...': intl.formatMessage({
        id: 'Delete bikerbed ...',
        defaultMessage: 'Delete bikerbed ...',
      }),
      'You are about to delete your bike club. All data about your Bike Club will be deleted.':
        intl.formatMessage({
          id: 'You are about to delete your bike club. All data about your Bike Club will be deleted.',
          defaultMessage:
            'You are about to delete your bike club. All data about your Bike Club will be deleted.',
        }),
      'You are about to delete your bikerbed. All data about your BikerBed will be deleted.':
        intl.formatMessage({
          id: 'You are about to delete your bikerbed. All data about your BikerBed will be deleted.',
          defaultMessage:
            'You are about to delete your bikerbed. All data about your BikerBed will be deleted.',
        }),
      'Are you sure turn off automatic renewal?': intl.formatMessage({
        id: 'Are you sure turn off automatic renewal?',
        defaultMessage: 'Are you sure turn off automatic renewal?',
      }),
      'Login to see your clubs': intl.formatMessage({
        id: 'Login to see your clubs',
        defaultMessage: 'Login to see your clubs',
      }),
      'Cannot load': intl.formatMessage({
        id: 'Cannot load',
        defaultMessage: 'Cannot load',
      }),
      'Add YouTube video': intl.formatMessage({
        id: 'Add YouTube video',
        defaultMessage: 'Add YouTube video',
      }),
      'BikerBed does not exist or is not active': intl.formatMessage({
        id: 'BikerBed does not exist or is not active',
        defaultMessage: 'BikerBed does not exist or is not active',
      }),
      CLOSE: intl.formatMessage({ id: 'CLOSE', defaultMessage: 'CLOSE' }),
      MORE: intl.formatMessage({ id: 'MORE', defaultMessage: 'MORE' }),
      'NAF Tour': intl.formatMessage({
        id: 'NAF Tour',
        defaultMessage: 'NAF Tour',
      }),
      'Share tour': intl.formatMessage({
        id: 'Share tour',
        defaultMessage: 'Share tour',
      }),
      'Delete the day?': intl.formatMessage({
        id: 'Delete the day?',
        defaultMessage: 'Delete the day?',
      }),
      'Enter your e-mail address': intl.formatMessage({
        id: 'Enter your e-mail address',
        defaultMessage: 'Enter your e-mail address',
      }),
      'Confirm your e-mail': intl.formatMessage({
        id: 'Confirm your e-mail',
        defaultMessage: 'Confirm your e-mail',
      }),
      'Confirm e-mail address': intl.formatMessage({
        id: 'Confirm e-mail address',
        defaultMessage: 'Confirm e-mail address',
      }),
      'Email List': intl.formatMessage({
        id: 'Email List',
        defaultMessage: 'Email List',
      }),
      Margueritruten: intl.formatMessage({
        id: 'Margueritruten',
        defaultMessage: 'Margueritruten',
      }),
      'Login to use home address': intl.formatMessage({
        id: 'Login to use home address',
        defaultMessage: 'Login to use home address',
      }),
      'Login to use work address': intl.formatMessage({
        id: 'Login to use work address',
        defaultMessage: 'Login to use work address',
      }),
      'Share place': intl.formatMessage({
        id: 'Share place',
        defaultMessage: 'Share place',
      }),
      Done: intl.formatMessage({ id: 'Done', defaultMessage: 'Done' }),
      'TOURSTART TOUR FINDER': intl.formatMessage({
        id: 'TOURSTART TOUR FINDER',
        defaultMessage: 'TOURSTART TOUR FINDER',
      }),
      'Search an area': intl.formatMessage({
        id: 'Search an area',
        defaultMessage: 'Search an area',
      }),
      'Region or address': intl.formatMessage({
        id: 'Region or address',
        defaultMessage: 'Region or address',
      }),
      'Update card info': intl.formatMessage({
        id: 'Update card info',
        defaultMessage: 'Update card info',
      }),
      Embed: intl.formatMessage({ id: 'Embed', defaultMessage: 'Embed' }),
      'Powered by': intl.formatMessage({
        id: 'Powered by',
        defaultMessage: 'Powered by',
      }),
      Text: intl.formatMessage({ id: 'Text', defaultMessage: 'Text' }),
      Height: intl.formatMessage({ id: 'Height', defaultMessage: 'Height' }),
      Width: intl.formatMessage({ id: 'Width', defaultMessage: 'Width' }),
      'keep ratio': intl.formatMessage({
        id: 'keep ratio',
        defaultMessage: 'keep ratio',
      }),
      'Paste the HTML to embed into a website': intl.formatMessage({
        id: 'Paste the HTML to embed into a website',
        defaultMessage: 'Paste the HTML to embed into a website',
      }),
      'In this embed option...': intl.formatMessage({
        id: 'In this embed option, the users are diverted to tourstart.org when they click on the map. On Tourstart they can plan the tour and download it directly to the GPS. This option is good where there is a limited space available.',
        defaultMessage: `In this embed option, the users are diverted to tourstart.org when they click on the map. On Tourstart they can plan the tour and download it directly to the GPS. This option is good where there is a limited space available.`,
      }),
      Default: intl.formatMessage({ id: 'Default', defaultMessage: 'Default' }),
      Satellite: intl.formatMessage({
        id: 'Satellite',
        defaultMessage: 'Satellite',
      }),
      'You are a': intl.formatMessage({
        id: 'You are a',
        defaultMessage: 'You are a',
      }),
      PRO: intl.formatMessage({ id: 'PRO', defaultMessage: 'PRO' }),
      FREE: intl.formatMessage({ id: 'FREE', defaultMessage: 'FREE' }),
      'motorcycle rider': intl.formatMessage({
        id: 'motorcycle rider',
        defaultMessage: 'motorcycle rider',
      }),
      'Remaining days as PRO': intl.formatMessage({
        id: 'Remaining days as PRO',
        defaultMessage: 'Remaining days as PRO',
      }),
      'Free trial for new users': intl.formatMessage({
        id: 'Free trial for new users',
        defaultMessage: 'Free trial for new users',
      }),
      'Automatic renewal': intl.formatMessage({
        id: 'Automatic renewal',
        defaultMessage: 'Automatic renewal',
      }),
      enabled: intl.formatMessage({ id: 'enabled', defaultMessage: 'enabled' }),
      disabled: intl.formatMessage({
        id: 'disabled',
        defaultMessage: 'disabled',
      }),
      'Create account & Log in': intl.formatMessage({
        id: 'Create account & Log in',
        defaultMessage: 'Create account & Log in',
      }),
      'Tourstart PRO': intl.formatMessage({
        id: 'Tourstart PRO',
        defaultMessage: 'Tourstart PRO',
      }),
      WEB: intl.formatMessage({ id: 'WEB', defaultMessage: 'WEB' }),
      APP: intl.formatMessage({ id: 'APP', defaultMessage: 'APP' }),
      'Transfer to GPS': intl.formatMessage({
        id: 'Transfer to GPS',
        defaultMessage: 'Transfer to GsPS',
      }),
      'Android Auto': intl.formatMessage({
        id: 'Android Auto',
        defaultMessage: 'Android Auto',
      }),
      'Apple CarPlay': intl.formatMessage({
        id: 'Apple CarPlay',
        defaultMessage: 'Apple CarPlay',
      }),
      'Free PRO access': intl.formatMessage({
        id: 'Free PRO access',
        defaultMessage: 'Free PRO access',
      }),
      'Free PRO access is exclusive to partner members.': intl.formatMessage({
        id: 'Free PRO access is exclusive to partner members.',
        defaultMessage: 'Free PRO access is exclusive to partner members.',
      }),
      'Join the club and enjoy the free access.': intl.formatMessage({
        id: 'Join the club and enjoy the free access.',
        defaultMessage: 'Join the club and enjoy the free access.',
      }),
      Close: intl.formatMessage({ id: 'Close', defaultMessage: 'Close' }),
      'Find the voucher code': intl.formatMessage({
        id: 'Find the voucher code',
        defaultMessage: 'Find the voucher code',
      }),
      'Use the voucher code': intl.formatMessage({
        id: 'Use the voucher code',
        defaultMessage: 'Use the voucher code',
      }),
      'Become NAF MC member': intl.formatMessage({
        id: 'Become NAF MC member',
        defaultMessage: 'Become NAF MC member',
      }),
      'Become BMW FI member': intl.formatMessage({
        id: 'Become BMW FI member',
        defaultMessage: 'Become BMW FI member',
      }),
      'Become BMW DK member': intl.formatMessage({
        id: 'Become BMW DK member',
        defaultMessage: 'Become BMW DK member',
      }),
      'Become GWCDK member': intl.formatMessage({
        id: 'Become GWCDK member',
        defaultMessage: 'Become GWCDK member',
      }),
      'Become BMW HH member': intl.formatMessage({
        id: 'Become BMW HH member',
        defaultMessage: 'Become BMW HH member',
      }),
      'Contact Tourstart today to secure free access for your club at jap@torstart.org.':
        intl.formatMessage({
          id: 'Contact Tourstart today to secure free access for your club at jap@torstart.org.',
          defaultMessage:
            'Contact Tourstart today to secure free access for your club at jap@torstart.org.',
        }),
      'Jan Agnoletti Pedersen, Founder of Tourstart': intl.formatMessage({
        id: 'Jan Agnoletti Pedersen, Founder of Tourstart',
        defaultMessage: 'Jan Agnoletti Pedersen, Founder of Tourstart',
      }),
      'Extend your PRO': intl.formatMessage({
        id: 'Extend your PRO',
        defaultMessage: 'Extend your PRO',
      }),
      'Become a PRO': intl.formatMessage({
        id: 'Become a PRO',
        defaultMessage: 'Become a PRO',
      }),
      year: intl.formatMessage({ id: 'year', defaultMessage: 'year' }),
      for: intl.formatMessage({ id: 'for', defaultMessage: 'for' }),
      per: intl.formatMessage({ id: 'per', defaultMessage: 'per' }),
      discount: intl.formatMessage({
        id: 'discount',
        defaultMessage: 'discount',
      }),
      Discount: intl.formatMessage({
        id: 'Discount',
        defaultMessage: 'Discount',
      }),
      'Voucher code is not valid or already used': intl.formatMessage({
        id: 'Voucher code is not valid or already used',
        defaultMessage: 'Voucher code is not valid or already used',
      }),
      'NAF MC have 17,000 members.': intl.formatMessage({
        id: 'NAF MC have 17,000 members.',
        defaultMessage: 'NAF MC have 17,000 members.',
      }),
      'NAF MC focuses on social activities and road safety.':
        intl.formatMessage({
          id: 'NAF MC focuses on social activities and road safety.',
          defaultMessage:
            'NAF MC focuses on social activities and road safety.',
        }),
      'Members are offered driving technical courses, themed evening, meetings and trips':
        intl.formatMessage({
          id: 'Members are offered driving technical courses, themed evening, meetings and trips',
          defaultMessage:
            'Members are offered driving technical courses, themed evening, meetings and trips',
        }),
      'Read more about NAF MC': intl.formatMessage({
        id: 'Read more about NAF MC',
        defaultMessage: 'Read more about NAF MC',
      }),
      'Read more about BMW FI': intl.formatMessage({
        id: 'Read more about BMW FI',
        defaultMessage: 'Read more about BMW FI',
      }),
      'Read more about BMW DK': intl.formatMessage({
        id: 'Read more about BMW DK',
        defaultMessage: 'Read more about BMW DK',
      }),
      'Read more about BMW HH': intl.formatMessage({
        id: 'Read more about BMW HH',
        defaultMessage: 'Read more about BMW HH',
      }),
      'Read more about GWCDK': intl.formatMessage({
        id: 'Read more about GWCDK',
        defaultMessage: 'Read more about GWCDK',
      }),
      'NAF MC member': intl.formatMessage({
        id: 'NAF MC member',
        defaultMessage: 'NAF MC member',
      }),
      'BMW FI member': intl.formatMessage({
        id: 'BMW FI member',
        defaultMessage: 'BMW FI member',
      }),
      'BMW DK member': intl.formatMessage({
        id: 'BMW DK member',
        defaultMessage: 'BMW DK member',
      }),
      'GWCDK member': intl.formatMessage({
        id: 'GWCDK member',
        defaultMessage: 'GWCDK member',
      }),
      'Already a member of NAF MC?': intl.formatMessage({
        id: 'Already a member of NAF MC?',
        defaultMessage: 'Already a member of NAF MC?',
      }),
      'Already a member of BMW FI?': intl.formatMessage({
        id: 'Already a member of BMW FI?',
        defaultMessage: 'Already a member of BMW FI?',
      }),
      'Already a member of BMW DK?': intl.formatMessage({
        id: 'Already a member of BMW DK?',
        defaultMessage: 'Already a member of BMW DK?',
      }),
      'Already a member of BMW HH?': intl.formatMessage({
        id: 'Already a member of BMW HH?',
        defaultMessage: 'Already a member of BMW HH?',
      }),
      'Already a member of GWCDK?': intl.formatMessage({
        id: 'Already a member of GWCDK?',
        defaultMessage: 'Already a member of GWCDK?',
      }),
      '-use your personal gift card code at Tourstart.org!': intl.formatMessage(
        {
          id: '-use your personal gift card code at Tourstart.org!',
          defaultMessage: '-use your personal gift card code at Tourstart.org!',
        }
      ),
      'Not a member?': intl.formatMessage({
        id: 'Not a member?',
        defaultMessage: 'Not a member?',
      }),
      '-just click on the "Become a NAF MC member"': intl.formatMessage({
        id: '-just click on the "Become a NAF MC member"',
        defaultMessage: '-just click on the "Become a NAF MC member"',
      }),
      '-just click on the "Become a BMW FI member"': intl.formatMessage({
        id: '-just click on the "Become a BMW FI member"',
        defaultMessage: '-just click on the "Become a BMW FI member"',
      }),
      '-just click on the "Become a BMW DK member"': intl.formatMessage({
        id: '-just click on the "Become a BMW DK member"',
        defaultMessage: '-just click on the "Become a BMW DK member"',
      }),
      '-just click on the "Become a BMW HH member"': intl.formatMessage({
        id: '-just click on the "Become a BMW HH member"',
        defaultMessage: '-just click on the "Become a BMW HH member"',
      }),
      '-just click on the "Become a GWCDK member"': intl.formatMessage({
        id: '-just click on the "Become a GWCDK member"',
        defaultMessage: '-just click on the "Become a GWCDK member"',
      }),
      'NAF MC sends out newsletters regularly to members. You will find the voucher code in the latest newsletter from NAF MC. Have not received the newsletter? Check your personal settings on NAF.no and make sure you have accepted communication with NAF on your browser and email.':
        intl.formatMessage({
          id: 'NAF MC sends out newsletters regularly to members. You will find the voucher code in the latest newsletter from NAF MC. Have not received the newsletter? Check your personal settings on NAF.no and make sure you have accepted communication with NAF on your browser and email.',
          defaultMessage:
            'NAF MC sends out newsletters regularly to members. You will find the voucher code in the latest newsletter from NAF MC. Have not received the newsletter? Check your personal settings on NAF.no and make sure you have accepted communication with NAF on your browser and email.',
        }),
      'About NAF MC, Norway': intl.formatMessage({
        id: 'About NAF MC, Norway',
        defaultMessage: 'About NAF MC, Norway',
      }),
      'About BMW MC Club Finland': intl.formatMessage({
        id: 'About BMW MC Club Finland',
        defaultMessage: 'About BMW MC Club Finland',
      }),
      'About BMW MC Club Denmark': intl.formatMessage({
        id: 'About BMW MC Club Denmark',
        defaultMessage: 'About BMW MC Club Denmark',
      }),
      'About BMW Motorradclub Hamburg': intl.formatMessage({
        id: 'About BMW Motorradclub Hamburg',
        defaultMessage: 'About BMW Motorradclub Hamburg',
      }),
      'About GoldWing Club Denmark': intl.formatMessage({
        id: 'About GoldWing Club Denmark',
        defaultMessage: 'About GoldWing Club Denmark',
      }),
      'NAF MCis the largest motorcycle organisation in Norway with more than 17,000 members.':
        intl.formatMessage({
          id: 'NAF MCis the largest motorcycle organisation in Norway with more than 17,000 members.',
          defaultMessage:
            'NAF MCis the largest motorcycle organisation in Norway with more than 17,000 members.',
        }),
      'Here are some of the benefits you have as a member:': intl.formatMessage(
        {
          id: 'Here are some of the benefits you have as a member:',
          defaultMessage: 'Here are some of the benefits you have as a member:',
        }
      ),
      'Free access to Tourstart.org': intl.formatMessage({
        id: 'Free access to Tourstart.org',
        defaultMessage: 'Free access to Tourstart.org',
      }),
      'Free roadside assistance': intl.formatMessage({
        id: 'Free roadside assistance',
        defaultMessage: 'Free roadside assistance',
      }),
      'Key insurance': intl.formatMessage({
        id: 'Key insurance',
        defaultMessage: 'Key insurance',
      }),
      'Legal services': intl.formatMessage({
        id: 'Legal services',
        defaultMessage: 'Legal services',
      }),
      'Tour guide "Norway is best motorcycle tours"': intl.formatMessage({
        id: 'Tour guide "Norway is best motorcycle tours"',
        defaultMessage: 'Tour guide "Norway is best motorcycle tours"',
      }),
      'Safe on the motorcycle - free driver development course on the way':
        intl.formatMessage({
          id: 'Safe on the motorcycle - free driver development course on the way',
          defaultMessage:
            'Safe on the motorcycle - free driver development course on the way',
        }),
      'Course evenings, trips and membership evenings under the auspices of our MC clubs':
        intl.formatMessage({
          id: 'Course evenings, trips and membership evenings under the auspices of our MC clubs',
          defaultMessage:
            'Course evenings, trips and membership evenings under the auspices of our MC clubs',
        }),
      'Free contract when buying and selling MC': intl.formatMessage({
        id: 'Free contract when buying and selling MC',
        defaultMessage: 'Free contract when buying and selling MC',
      }),
      "Free access to NAF's benefit program": intl.formatMessage({
        id: "Free access to NAF's benefit program",
        defaultMessage: "Free access to NAF's benefit program",
      }),
      'Make life a ride': intl.formatMessage({
        id: 'Make life a ride',
        defaultMessage: 'Make life a ride',
      }),
      'We are more than 2,800 BMW riders in Finland': intl.formatMessage({
        id: 'We are more than 2,800 BMW riders in Finland',
        defaultMessage: 'We are more than 2,800 BMW riders in Finland',
      }),
      'Join us and become a part of the BMW community': intl.formatMessage({
        id: 'Join us and become a part of the BMW community',
        defaultMessage: 'Join us and become a part of the BMW community',
      }),
      'You can see the voucher code on your login on the BMW club page.':
        intl.formatMessage({
          id: 'You can see the voucher code on your login on the BMW club page.',
          defaultMessage:
            'You can see the voucher code on your login on the BMW club page.',
        }),
      'The BMW Motorcycle Club of Finland was founded in autumn 1976 in Kangasala and it operates nationwide.':
        intl.formatMessage({
          id: 'The BMW Motorcycle Club of Finland was founded in autumn 1976 in Kangasala and it operates nationwide.',
          defaultMessage:
            'The BMW Motorcycle Club of Finland was founded in autumn 1976 in Kangasala and it operates nationwide.',
        }),
      'Mission of the club is to unite the BMW motorcycle riders through organizing meetings and common activities as well as publishing the club magazine and maintaining the club web page.':
        intl.formatMessage({
          id: 'Mission of the club is to unite the BMW motorcycle riders through organizing meetings and common activities as well as publishing the club magazine and maintaining the club web page.',
          defaultMessage:
            'Mission of the club is to unite the BMW motorcycle riders through organizing meetings and common activities as well as publishing the club magazine and maintaining the club web page.',
        }),
      'Club maintains and enhances BMW related knowledge of its members by organizing training and presentations.':
        intl.formatMessage({
          id: 'Club maintains and enhances BMW related knowledge of its members by organizing training and presentations.',
          defaultMessage:
            'Club maintains and enhances BMW related knowledge of its members by organizing training and presentations.',
        }),
      'The membership period is one calendar year, and the membership fee (2025) of 30 EUR is paid once a year.':
        intl.formatMessage({
          id: 'The membership period is one calendar year, and the membership fee (2025) of 30 EUR is paid once a year.',
          defaultMessage:
            'The membership period is one calendar year, and the membership fee (2025) of 30 EUR is paid once a year.',
        }),
      'The club is sponsored by BMW Motorrad.': intl.formatMessage({
        id: 'The club is sponsored by BMW Motorrad.',
        defaultMessage: 'The club is sponsored by BMW Motorrad.',
      }),
      'Ups - something went wrong.': intl.formatMessage({
        id: 'Ups - something went wrong.',
        defaultMessage: 'Ups - something went wrong.',
      }),
      'Questions? Write to info@tourstart.org': intl.formatMessage({
        id: 'Questions? Write to info@tourstart.org',
        defaultMessage: 'Questions? Write to info@tourstart.org',
      }),
      'Welcome to PRO': intl.formatMessage({
        id: 'Welcome to PRO',
        defaultMessage: 'Welcome to PRO',
      }),
      'Experience all our exclusive perks:': intl.formatMessage({
        id: 'Experience all our exclusive perks:',
        defaultMessage: 'Experience all our exclusive perks:',
      }),
      'Unlock top-tier routes': intl.formatMessage({
        id: 'Unlock top-tier routes',
        defaultMessage: 'Unlock top-tier routes',
      }),
      'Create every ride with powerful tools': intl.formatMessage({
        id: 'Create every ride with powerful tools',
        defaultMessage: 'Create every ride with powerful tools',
      }),
      'You are': intl.formatMessage({
        id: 'You are',
        defaultMessage: 'You are',
      }),
      until: intl.formatMessage({ id: 'until', defaultMessage: 'until' }),
      'Ride into new adventures': intl.formatMessage({
        id: 'Ride into new adventures',
        defaultMessage: 'Ride into new adventures',
      }),
      '- so let us hit road.': intl.formatMessage({
        id: '- so let us hit road.',
        defaultMessage: '- so let us hit road.',
      }),
      'Download receipt': intl.formatMessage({
        id: 'Download receipt',
        defaultMessage: 'Download receipt',
      }),
      'You PRO is extended': intl.formatMessage({
        id: 'You PRO is extended',
        defaultMessage: 'You PRO is extended',
      }),
      'You are now set for more adventures with the PRO extended to keep you fully equipped for the roads ahead.':
        intl.formatMessage({
          id: 'You are now set for more adventures with the PRO extended to keep you fully equipped for the roads ahead.',
          defaultMessage:
            'You are now set for more adventures with the PRO extended to keep you fully equipped for the roads ahead.',
        }),
      '- let`s make this year unforgettable.': intl.formatMessage({
        id: '- let`s make this year unforgettable.',
        defaultMessage: '- let`s make this year unforgettable.',
      }),
      'Welcome NAF MC member': intl.formatMessage({
        id: 'Welcome NAF MC member',
        defaultMessage: 'Welcome NAF MC member',
      }),
      'Welcome BMW FI member': intl.formatMessage({
        id: 'Welcome BMW FI member',
        defaultMessage: 'Welcome BMW FI member',
      }),
      'Welcome BMW DK member': intl.formatMessage({
        id: 'Welcome BMW DK member',
        defaultMessage: 'Welcome BMW DK member',
      }),
      'Welcome GWCDK member': intl.formatMessage({
        id: 'Welcome GWCDK member',
        defaultMessage: 'Welcome GWCDK member',
      }),
      'Awesome! Get ready to enjoy exclusive NAF MC member benefits and hit the road with fantastic motorcycle routes.':
        intl.formatMessage({
          id: 'Awesome! Get ready to enjoy exclusive NAF MC member benefits and hit the road with fantastic motorcycle routes.',
          defaultMessage:
            'Awesome! Get ready to enjoy exclusive NAF MC member benefits and hit the road with fantastic motorcycle routes.',
        }),
      'Awesome! Get ready to enjoy exclusive BMW FI member benefits and hit the road with fantastic motorcycle routes.':
        intl.formatMessage({
          id: 'Awesome! Get ready to enjoy exclusive BMW FI member benefits and hit the road with fantastic motorcycle routes.',
          defaultMessage:
            'Awesome! Get ready to enjoy exclusive BMW FI member benefits and hit the road with fantastic motorcycle routes.',
        }),
      'Awesome! Get ready to enjoy exclusive BMW DK member benefits and hit the road with fantastic motorcycle routes.':
        intl.formatMessage({
          id: 'Awesome! Get ready to enjoy exclusive BMW DK member benefits and hit the road with fantastic motorcycle routes.',
          defaultMessage:
            'Awesome! Get ready to enjoy exclusive BMW DK member benefits and hit the road with fantastic motorcycle routes.',
        }),
      'Awesome! Get ready to enjoy exclusive GWCDK member benefits and hit the road with fantastic motorcycle routes.':
        intl.formatMessage({
          id: 'Awesome! Get ready to enjoy exclusive GWCDK member benefits and hit the road with fantastic motorcycle routes.',
          defaultMessage:
            'Awesome! Get ready to enjoy exclusive GWCDK member benefits and hit the road with fantastic motorcycle routes.',
        }),
      'You have': intl.formatMessage({
        id: 'You have',
        defaultMessage: 'You have',
      }),
      'FREE PRO ACCESS': intl.formatMessage({
        id: 'FREE PRO ACCESS',
        defaultMessage: 'FREE PRO ACCESS',
      }),
      'You need to login or create an account as to enjoy this fantastic offer.':
        intl.formatMessage({
          id: 'You need to login or create an account as to enjoy this fantastic offer.',
          defaultMessage:
            'You need to login or create an account as to enjoy this fantastic offer.',
        }),
      Activate: intl.formatMessage({
        id: 'Activate',
        defaultMessage: 'Activate',
      }),
      days: intl.formatMessage({ id: 'days', defaultMessage: 'days' }),
      month: intl.formatMessage({ id: 'month', defaultMessage: 'month' }),
      months: intl.formatMessage({ id: 'months', defaultMessage: 'months' }),
      'Tourstart Pro subscription': intl.formatMessage({
        id: 'Tourstart Pro subscription',
        defaultMessage: 'Tourstart Pro subscription',
      }),
      'Welcome to Tourstart': intl.formatMessage({
        id: 'Welcome to Tourstart',
        defaultMessage: 'Welcome to Tourstart',
      }),
      'As a NAF MC member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.':
        intl.formatMessage({
          id: 'As a NAF MC member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.',
          defaultMessage:
            'As a NAF MC member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.',
        }),
      'As a BMW FI member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.':
        intl.formatMessage({
          id: 'As a BMW FI member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.',
          defaultMessage:
            'As a BMW FI member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.',
        }),
      'As a BMW DK member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.':
        intl.formatMessage({
          id: 'As a BMW DK member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.',
          defaultMessage:
            'As a BMW DK member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.',
        }),
      'As a BMW HH member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.':
        intl.formatMessage({
          id: 'As a BMW HH member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.',
          defaultMessage:
            'As a BMW HH member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.',
        }),
      'As a GWCDK member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.':
        intl.formatMessage({
          id: 'As a GWCDK member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.',
          defaultMessage:
            'As a GWCDK member you have exclusive and free access to Tourstart PRO. Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.',
        }),
      'Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.':
        intl.formatMessage({
          id: 'Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.',
          defaultMessage:
            'Activate your Tourstart PRO below and become inspired of the many tours - or plan your own favorite tours.',
        }),
      'NAF MC, Norway': intl.formatMessage({
        id: 'NAF MC, Norway',
        defaultMessage: 'NAF MC, Norway',
      }),
      'BMW MC Club Finland': intl.formatMessage({
        id: 'BMW MC Club Finland',
        defaultMessage: 'BMW MC Club Finland',
      }),
      'BMW MC Club Denmark': intl.formatMessage({
        id: 'BMW MC Club Denmark',
        defaultMessage: 'BMW MC Club Denmark',
      }),
      'BMW Motorradclub Hamburg': intl.formatMessage({
        id: 'BMW Motorradclub Hamburg',
        defaultMessage: 'BMW Motorradclub Hamburg',
      }),
      'GoldWing Club Denmark': intl.formatMessage({
        id: 'GoldWing Club Denmark',
        defaultMessage: 'GoldWing Club Denmark',
      }),
      'NAF MC has over 17,000 members and 27 local branches throughout Norway. As a member of NAF MC, you enjoy a number of benefits. Do like over 17,000 other motorcyclists - sign up for NAF MC today!':
        intl.formatMessage({
          id: 'NAF MC has over 17,000 members and 27 local branches throughout Norway. As a member of NAF MC, you enjoy a number of benefits. Do like over 17,000 other motorcyclists - sign up for NAF MC today!',
          defaultMessage:
            'NAF MC has over 17,000 members and 27 local branches throughout Norway. As a member of NAF MC, you enjoy a number of benefits. Do like over 17,000 other motorcyclists - sign up for NAF MC today!',
        }),
      'BMW MC Club Denmark is the official club for BMW MC in Denmark. BMW MC Club Denmark started in 1972 and today has almost 2,000 members.':
        intl.formatMessage({
          id: 'BMW MC Club Denmark is the official club for BMW MC in Denmark. BMW MC Club Denmark started in 1972 and today has almost 2,000 members.',
          defaultMessage:
            'BMW MC Club Denmark is the official club for BMW MC in Denmark. BMW MC Club Denmark started in 1972 and today has almost 2,000 members.',
        }),
      'The BMW Motorradclub Hamburg is one of the largest official BMW motorcycle clubs in Germany, with more than 120 members.':
        intl.formatMessage({
          id: 'The BMW Motorradclub Hamburg is one of the largest official BMW motorcycle clubs in Germany, with more than 120 members.',
          defaultMessage:
            'The BMW Motorradclub Hamburg is one of the largest official BMW motorcycle clubs in Germany, with more than 120 members.',
        }),
      'Are you a "GoldWinger"?': intl.formatMessage({
        id: 'Are you a "GoldWinger"?',
        defaultMessage: 'Are you a "GoldWinger"?',
      }),
      'Then our club might be something for you!': intl.formatMessage({
        id: 'Then our club might be something for you!',
        defaultMessage: 'Then our club might be something for you!',
      }),
      'Club member?': intl.formatMessage({
        id: 'Club member?',
        defaultMessage: 'Club member?',
      }),
      '-join 17,000 other motorcyclists. Join by pressing the button.':
        intl.formatMessage({
          id: '-join 17,000 other motorcyclists. Join by pressing the button.',
          defaultMessage:
            '-join 17,000 other motorcyclists. Join by pressing the button.',
        }),
      'You can see the voucher code on your login on the club page.':
        intl.formatMessage({
          id: 'You can see the voucher code on your login on the club page.',
          defaultMessage:
            'You can see the voucher code on your login on the club page.',
        }),
      'With over 17,000 members, NAF MC is Norway is largest dedicated membership organization for motorcyclists.':
        intl.formatMessage({
          id: 'With over 17,000 members, NAF MC is Norway is largest dedicated membership organization for motorcyclists.',
          defaultMessage:
            'With over 17,000 members, NAF MC is Norway is largest dedicated membership organization for motorcyclists.',
        }),
      'The purpose of the club is to bring people with an interest in BMW MC into contact with each other, and to establish contacts with other BMW clubs.':
        intl.formatMessage({
          id: 'The purpose of the club is to bring people with an interest in BMW MC into contact with each other, and to establish contacts with other BMW clubs.',
          defaultMessage:
            'The purpose of the club is to bring people with an interest in BMW MC into contact with each other, and to establish contacts with other BMW clubs.',
        }),
      'A membership in BMW MC Club Danmark costs DKK 250. per year (calendar year). If you register later than 1 October, the payment also covers the following year.':
        intl.formatMessage({
          id: 'A membership in BMW MC Club Danmark costs DKK 250. per year (calendar year). If you register later than 1 October, the payment also covers the following year.',
          defaultMessage:
            'A membership in BMW MC Club Danmark costs DKK 250. per year (calendar year). If you register later than 1 October, the payment also covers the following year.',
        }),
      'If you drive a BMW motorcycle, or are thinking of getting one, then BMW MC Club is the right choice.':
        intl.formatMessage({
          id: 'If you drive a BMW motorcycle, or are thinking of getting one, then BMW MC Club is the right choice.',
          defaultMessage:
            'If you drive a BMW motorcycle, or are thinking of getting one, then BMW MC Club is the right choice.',
        }),
      'The club publishes the membership magazine "Club information" 4 times a year. Through this, members are kept informed about the club is many activities.':
        intl.formatMessage({
          id: 'The club publishes the membership magazine "Club information" 4 times a year. Through this, members are kept informed about the club is many activities.',
          defaultMessage:
            'The club publishes the membership magazine "Club information" 4 times a year. Through this, members are kept informed about the club is many activities.',
        }),
      'Among the club is many different activities, we can mention the regular driving trips on different days of the week, coffee meetings, driving lessons, trips abroad and socializing.':
        intl.formatMessage({
          id: 'Among the club is many different activities, we can mention the regular driving trips on different days of the week, coffee meetings, driving lessons, trips abroad and socializing.',
          defaultMessage:
            'Among the club is many different activities, we can mention the regular driving trips on different days of the week, coffee meetings, driving lessons, trips abroad and socializing.',
        }),
      'GoldWing Club Denmark (GWCDK) is a nationwide brand club for motorcycles of the Honda GoldWing brand. It was started in 1979 with the aim of bringing together Danish GW riders around common interests. The club currently has approximately 520 members. Membership is available to all GoldWing owners, and the club publishes a club magazine 4 times a year. The club is membership is steadily increasing. In order to maintain close contact with the members, the club is divided into 5 regions, each with its own regional chairman. - You can see the division under the Regions.':
        intl.formatMessage({
          id: 'GoldWing Club Denmark (GWCDK) is a nationwide brand club for motorcycles of the Honda GoldWing brand. It was started in 1979 with the aim of bringing together Danish GW riders around common interests. The club currently has approximately 520 members. Membership is available to all GoldWing owners, and the club publishes a club magazine 4 times a year. The club is membership is steadily increasing. In order to maintain close contact with the members, the club is divided into 5 regions, each with its own regional chairman. - You can see the division under the Regions.',
          defaultMessage:
            'GoldWing Club Denmark (GWCDK) is a nationwide brand club for motorcycles of the Honda GoldWing brand. It was started in 1979 with the aim of bringing together Danish GW riders around common interests. The club currently has approximately 520 members. Membership is available to all GoldWing owners, and the club publishes a club magazine 4 times a year. The club is membership is steadily increasing. In order to maintain close contact with the members, the club is divided into 5 regions, each with its own regional chairman. - You can see the division under the Regions.',
        }),
      'Nationally, 3 - 4 fun weekends and an international meeting are held every other year. In addition, there are numerous coffee meetings, trips and other events in the individual regions.':
        intl.formatMessage({
          id: 'Nationally, 3 - 4 fun weekends and an international meeting are held every other year. In addition, there are numerous coffee meetings, trips and other events in the individual regions.',
          defaultMessage:
            'Nationally, 3 - 4 fun weekends and an international meeting are held every other year. In addition, there are numerous coffee meetings, trips and other events in the individual regions.',
        }),
      'Tourstart Pro': intl.formatMessage({
        id: 'Tourstart Pro',
        defaultMessage: 'Tourstart Pro',
      }),
      Administration: intl.formatMessage({
        id: 'Administration',
        defaultMessage: 'Administration',
      }),
      'Bike club relations': intl.formatMessage({
        id: 'Bike club relations',
        defaultMessage: 'Bike club relations',
      }),
      'BikerBeds relations': intl.formatMessage({
        id: 'BikerBeds relations',
        defaultMessage: 'BikerBeds relations',
      }),
      'Bike clubs relations': intl.formatMessage({
        id: 'Bike clubs relations',
        defaultMessage: 'Bike clubs relations',
      }),
      'BikerBed search': intl.formatMessage({
        id: 'BikerBed search',
        defaultMessage: 'BikerBed search',
      }),
      'Bike club search': intl.formatMessage({
        id: 'Bike club search',
        defaultMessage: 'Bike club search',
      }),
      'Members of our Bike Club': intl.formatMessage({
        id: 'Members of our Bike Club',
        defaultMessage: 'Members of our Bike Club',
      }),
      'Tourstart members': intl.formatMessage({
        id: 'Tourstart members',
        defaultMessage: 'Tourstart members',
      }),
      Accept: intl.formatMessage({ id: 'Accept', defaultMessage: 'Accept' }),
      Decline: intl.formatMessage({ id: 'Decline', defaultMessage: 'Decline' }),
      Visit: intl.formatMessage({ id: 'Visit', defaultMessage: 'Visit' }),
      Country: intl.formatMessage({ id: 'Country', defaultMessage: 'Country' }),
      Position: intl.formatMessage({
        id: 'Position',
        defaultMessage: 'Position',
      }),
      Status: intl.formatMessage({ id: 'Status', defaultMessage: 'Status' }),
      Administrator: intl.formatMessage({
        id: 'Administrator',
        defaultMessage: 'Administrator',
      }),
      Tours: intl.formatMessage({ id: 'Tours', defaultMessage: 'Tours' }),
      'Our team': intl.formatMessage({
        id: 'Our team',
        defaultMessage: 'Our team',
      }),
      'Do you want to remove this member?': intl.formatMessage({
        id: 'Do you want to remove this member?',
        defaultMessage: 'Do you want to remove this member?',
      }),
      'Do you want to reject this request?': intl.formatMessage({
        id: 'Do you want to reject this request?',
        defaultMessage: 'Do you want to reject this request?',
      }),
      Add: intl.formatMessage({ id: 'Add', defaultMessage: 'Add' }),
      Parent: intl.formatMessage({ id: 'Parent', defaultMessage: 'Parent' }),
      Branch: intl.formatMessage({ id: 'Branch', defaultMessage: 'Branch' }),
      Friend: intl.formatMessage({ id: 'Friend', defaultMessage: 'Friend' }),
      'Do you want to remove this club?': intl.formatMessage({
        id: 'Do you want to remove this club?',
        defaultMessage: 'Do you want to remove this club?',
      }),
      'There are not selected a bike club relation type. Make a selection.':
        intl.formatMessage({
          id: 'There are not selected a bike club relation type. Make a selection.',
          defaultMessage:
            'There are not selected a bike club relation type. Make a selection.',
        }),
      Pending: intl.formatMessage({ id: 'Pending', defaultMessage: 'Pending' }),
      Followers: intl.formatMessage({
        id: 'Followers',
        defaultMessage: 'Followers',
      }),
      'Do you want to remove this bikerBed?': intl.formatMessage({
        id: 'Do you want to remove this bikerBed?',
        defaultMessage: 'Do you want to remove this bikerBed?',
      }),
      'BikerBed relations': intl.formatMessage({
        id: 'BikerBed relations',
        defaultMessage: 'BikerBed relations',
      }),
      'Write a search word': intl.formatMessage({
        id: 'Write a search word',
        defaultMessage: 'Write a search word',
      }),
      'Login to see your BikerBeds': intl.formatMessage({
        id: 'Login to see your BikerBeds',
        defaultMessage: 'Login to see your BikerBeds',
      }),
      'No info yet': intl.formatMessage({
        id: 'No info yet',
        defaultMessage: 'No info yet',
      }),
      'No additional info yet': intl.formatMessage({
        id: 'No additional info yet',
        defaultMessage: 'No additional info yet',
      }),
      'No website info yet': intl.formatMessage({
        id: 'No website info yet',
        defaultMessage: 'No website info yet',
      }),
      'No address info yet': intl.formatMessage({
        id: 'No address info yet',
        defaultMessage: 'No address info yet',
      }),
      Club: intl.formatMessage({ id: 'Club', defaultMessage: 'Club' }),
      'BMW HH sends out newsletters regularly to members. You will find the voucher code in the latest newsletter.':
        intl.formatMessage({
          id: 'BMW HH sends out newsletters regularly to members. You will find the voucher code in the latest newsletter.',
          defaultMessage:
            'BMW HH sends out newsletters regularly to members. You will find the voucher code in the latest newsletter.',
        }),
      'Moin and welcome! Founded in 2009 and with more than 120 members, we are one of the largest BMW motorcycle clubs in Germany and part of the worldwide community of official BMW Clubs.':
        intl.formatMessage({
          id: 'Moin and welcome! Founded in 2009 and with more than 120 members, we are one of the largest BMW motorcycle clubs in Germany and part of the worldwide community of official BMW Clubs.',
          defaultMessage:
            'Moin and welcome! Founded in 2009 and with more than 120 members, we are one of the largest BMW motorcycle clubs in Germany and part of the worldwide community of official BMW Clubs.',
        }),
      'Our club life is defined by exciting rides, friendly gatherings, and supporting our members in all aspects of motorcycling. We regularly organize tours through breathtaking landscapes, visit events and trade fairs, and advocate for safety and respect in road traffic.':
        intl.formatMessage({
          id: 'Our club life is defined by exciting rides, friendly gatherings, and supporting our members in all aspects of motorcycling. We regularly organize tours through breathtaking landscapes, visit events and trade fairs, and advocate for safety and respect in road traffic.',
          defaultMessage:
            'Our club life is defined by exciting rides, friendly gatherings, and supporting our members in all aspects of motorcycling. We regularly organize tours through breathtaking landscapes, visit events and trade fairs, and advocate for safety and respect in road traffic.',
        }),
      'Our members come from all walks of life and age groups, united by their love for motorcycling. Whether you are an experienced biker or have just obtained your license, you will find a home on two wheels with us.':
        intl.formatMessage({
          id: 'Our members come from all walks of life and age groups, united by their love for motorcycling. Whether you are an experienced biker or have just obtained your license, you will find a home on two wheels with us.',
          defaultMessage:
            'Our members come from all walks of life and age groups, united by their love for motorcycling. Whether you are an experienced biker or have just obtained your license, you will find a home on two wheels with us.',
        }),
      News: intl.formatMessage({ id: 'News', defaultMessage: 'News' }),
    };
    return <WrappedComponent tr={translations} {...props} />;
  });
};

export default withTranslations;
