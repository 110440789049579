import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Divider } from 'antd';

import { history } from 'app/store';
import { publish } from 'app/libs/core/PubSub';
import * as way from 'app/libs/core/PubSubWays';
import { isMobileOnly } from 'react-device-detect';
import withTranslations from 'app/components/HOGs/withTranslations';
import { useActions } from 'app/components/Utility/hooks/useAction.hooks';
import checkPrevLink from 'app/components/Utility/checkPrevUrlLink';
import { pageScope } from 'app/constants/mapConstants';

import {
  cachHandler,
  openNotificationWithIcon,
} from 'app/components/Utility/notificationMessage';

import { clubAPI } from 'app/API/club.api';

import MobileClub from '../../../Mobile/BikeClub/bikeclubmobile';

import BikeClubViewFilters from './BikeClubFilters';
import DrawerBikeClub from './DrawerBikeClub';
import Cover from './Cover';
import Title from './Title';
import Contact from './Contact';
import About from './About';
import Admin from './Admin';
import Members from './Member';
import BikeClubCreate from './BikeClubCreate';
import BikerClubMedia from './Media';
import ClubNews from '../ClubNews/clubNews';

let currentClubUrl = null;
let loading = null;
const BikeClubView = (props) => {
  const { location, tr, page } = props;

  const { showLoading, hideLoading, scope, setDataClub } = useActions();

  const isLoggedIn = useSelector((store) => store.authentication.isLoggedIn);
  const loginLoad = useSelector((store) => store.authentication.loginLoad);
  const clubData = useSelector((store) => store.clubFullDataReducer.data);
  const isAdmin = useSelector(
    (store) => store.clubFullDataReducer.data.isAdmin
  );
  const [tours, setTours] = useState([]);
  const [poi, setPoi] = useState([]);
  const [openedScope, setOpenedScope] = useState(null);

  const { previousLocation, currentLocation } = useSelector(
    (store) => store.locationUrl
  );

  const id = props.clubId;

  const getTours = () => {
    clubAPI
      .getToursClub(id, props.scope)
      .then((res) => {
        const { code, response } = res;
        if (code === 200) {
          setTours(response);
        } else {
          console.log(res);
        }
      })
      .catch((err) => cachHandler(err));
  };

  const getWlaPoiList = () => {
    clubAPI
      .wlaClubPoi(id)
      .then((res) => {
        const { code, response } = res;
        if (code === 200) {
          setPoi(response);
        }
      })
      .catch((err) => cachHandler(err));
  };

  const getClubMainData = () => {
    clubAPI
      .getClubFullInfo(id)
      .then((res) => {
        const { code, response } = res;
        if (code === 200) {
          if (page === 'edit' && !response.isAdmin) {
            history.push('/club/my/list');
          }
          setDataClub(response);
          getTours();
          getWlaPoiList();
          hideLoading();
          loading = false;
        } else {
          openNotificationWithIcon(
            'error',
            tr['Bikerclub does not exist or is not active']
          );
          checkPrevLink(previousLocation, currentLocation);
          hideLoading();
        }
      })
      .catch((err) => {
        cachHandler(err);
        hideLoading();
      });
  };

  useEffect(() => {
    scope(pageScope.club_view);
    currentClubUrl = location.pathname;
    return () => {
      setDataClub({});
      publish(way.CLUB_MAP_CLEAN);
      currentClubUrl = null;
    };
  }, []);

  useEffect(() => {
    loading = true;
    showLoading();
    getClubMainData();
    return () => {
      if (
        location.pathname.split('/').slice(-1)[0] === 'list' ||
        location.pathname === currentClubUrl
      ) {
        return;
      }
      setPoi([]);
      publish(way.CLUB_MAP_CLEAN);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (isLoggedIn && !loginLoad && !loading) {
      showLoading();
      getClubMainData();
    }
  }, [isLoggedIn, loginLoad]);

  const GetDivider = () => {
    return (
      <div className="divider_layout">
        <Divider className="divider" />
      </div>
    );
  };

  const isNews = isAdmin && +id === +process.env.CLUB_NEWS_ID;

  const getContentClub = () => {
    return (
      <div className="biker_club_layout">
        <Cover />
        <Title />
        <GetDivider />
        <Admin divider={<GetDivider />} />
        {isNews && (
          <>
            <ClubNews />
            <GetDivider />
          </>
        )}
        <Contact />
        <GetDivider />
        <Members divider={<GetDivider />} />
        <About />
        <GetDivider />
        <BikerClubMedia
          scope={pageScope.media}
          setOpenedScope={setOpenedScope}
        />
        <GetDivider />
        <BikerClubMedia
          scope={pageScope.member}
          setOpenedScope={setOpenedScope}
        />
        <GetDivider />
        <BikerClubMedia
          scope={pageScope.club}
          setOpenedScope={setOpenedScope}
        />
        <GetDivider />
        <BikerClubMedia
          scope={pageScope.bikerbed}
          setOpenedScope={setOpenedScope}
        />
      </div>
    );
  };

  const getComponent = () => {
    if (page === 'edit') {
      return <BikeClubCreate page={page} />;
    }

    if (Object.keys(clubData).length > 0) {
      if (isMobileOnly) {
        return <MobileClub>{getContentClub()}</MobileClub>;
      }
      return (
        <>
          <DrawerBikeClub
            {...props}
            openedScope={openedScope}
            setOpenedScope={setOpenedScope}
          >
            {getContentClub()}
          </DrawerBikeClub>
          <BikeClubViewFilters
            clubData={clubData}
            tours={tours}
            poi={poi}
            openedScope={openedScope}
          />
        </>
      );
    }

    return null;
  };
  return getComponent();
};

BikeClubView.displayName = 'BikeClubView';

export default withTranslations(BikeClubView);
