import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Dropdown, Menu, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import withTranslations from 'app/components/HOGs/withTranslations';

import {
  addTourToFolder,
  removeTourFromFolder,
  removeTourFromAllFolders,
} from '../../../actions/Menu/folders-async.actions';

import { setMenuFolderCreateVisible } from 'actions/Menu/main_menu.action';

import folder_add from 'static/img/folder/folder_gray.svg';
import folder_green from 'static/img/folder/folder_green.svg';

const FolderMenuButton = ({
  tourId,
  folders,
  selectedFolders,
  tr,
  applying,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.authentication.user.id);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  let timeoutId = null;

  const leaveHandler = (delay = 300) => {
    setTooltipVisible(false);

    timeoutId = setTimeout(() => {
      setMenuVisible(false);
    }, delay);
  };

  function changeFolder(folderId) {
    if (selectedFolders.includes(folderId)) {
      dispatch(removeTourFromFolder(+folderId, applying, +tourId));
    } else if (folderId === '0') {
      dispatch(removeTourFromAllFolders(+userId, +tourId, applying));
    } else if (folderId === 'add') {
      dispatch(setMenuFolderCreateVisible(true));
    } else {
      dispatch(addTourToFolder(+folderId, applying, +tourId));
    }
    leaveHandler(100);
  }

  function getItems() {
    const items = [];

    items.push({
      label: (
        <Checkbox checked={selectedFolders.length === 0}>
          {tr['No folder']}
        </Checkbox>
      ),
      key: '0',
      className: 'folder-dropdown-item',
    });

    folders
      .sort((a, b) => b.order_id - a.order_id)
      .forEach((folder) => {
        items.push({
          label: (
            <Checkbox checked={selectedFolders.includes(`${folder.id}`)}>
              {folder.title}
            </Checkbox>
          ),
          key: folder.id,
          className: 'folder-dropdown-item',
        });
      });

    items.push({
      label: (
        <div aria-hidden className="create_folder_dropdown_item">
          <img src={folder_add} alt="" />
          {tr['Create folder']}
        </div>
      ),
      key: 'add',
      className: 'folder-dropdown-item',
    });

    return items;
  }

  const menu = (
    <Menu
      onClick={(e) => changeFolder(e.key)}
      multiple
      selectable={false}
      onMouseEnter={() => clearTimeout(timeoutId)}
      onMouseLeave={() => leaveHandler()}
      items={getItems()}
    />
  );

  const clickHandler = () => {
    setTooltipVisible(false);
    setMenuVisible(!menuVisible);
  };

  const isAdded = selectedFolders.length;

  return (
    <Tooltip
      title={
        isAdded ? (
          <FormattedMessage id="Change folder" defaultMessage="Change folder" />
        ) : (
          <FormattedMessage id="Add to folder" defaultMessage="Add to folder" />
        )
      }
      visible={tooltipVisible}
    >
      <Dropdown
        overlay={menu}
        trigger={['click']}
        visible={menuVisible}
        overlayClassName="folder-dropdown-menu"
        placement="bottom"
      >
        <Button
          className="changeFolder list_action_button"
          onMouseEnter={() => !menuVisible && setTooltipVisible(true)}
          onClick={clickHandler}
          onMouseLeave={() => leaveHandler()}
        >
          {isAdded ? (
            <img
              src={folder_green}
              alt=""
              style={{
                width: '17px',
                height: '17x',
              }}
            />
          ) : (
            <img
              src={folder_add}
              style={{
                width: '17px',
                height: '17px',
              }}
            />
          )}
        </Button>
      </Dropdown>
    </Tooltip>
  );
};
FolderMenuButton.propTypes = {
  folder: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    user_id: PropTypes.number,
    order_id: PropTypes.number,
    tours: PropTypes.arrayOf(PropTypes.number),
    deleted: PropTypes.oneOf([0, 1]),
  }),
  // selectedFolders: PropTypes.arrayOf(PropTypes.string),
  tourId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withTranslations(FolderMenuButton);
