import React from 'react';
import { useIntl } from 'react-intl';

const withRightMenuText = (WrappedComponent) => {
  return (props) => {
    const intl = useIntl();
    const rightMenusText = {
      'Start a tour from here': intl.formatMessage({
        id: 'Start a tour from here',
        defaultMessage: 'Start a tour from here',
      }),
      'Set home position': intl.formatMessage({
        id: 'Set home position',
        defaultMessage: 'Set home position',
      }),
      'Add to my place': intl.formatMessage({
        id: 'Add to my place',
        defaultMessage: 'Add to my place',
      }),
      'Set work position': intl.formatMessage({
        id: 'Set work position',
        defaultMessage: 'Set work position',
      }),
      'Set start point': intl.formatMessage({
        id: 'Set start point',
        defaultMessage: 'Set start point',
      }),
      'Set POI': intl.formatMessage({
        id: 'Set POI',
        defaultMessage: 'Set POI',
      }),
      'Set end point': intl.formatMessage({
        id: 'Set end point',
        defaultMessage: 'Set end point',
      }),
      'Edit point': intl.formatMessage({
        id: 'Edit point',
        defaultMessage: 'Edit point',
      }),
      Edit: intl.formatMessage({ id: 'Edit', defaultMessage: 'Edit' }),
      'Set via point': intl.formatMessage({
        id: 'Set via point',
        defaultMessage: 'Set via point',
      }),
      'Remove point': intl.formatMessage({
        id: 'Remove point',
        defaultMessage: 'Remove point',
      }),
      'Change to Start point': intl.formatMessage({
        id: 'Change to Start point',
        defaultMessage: 'Change to Start point',
      }),
      'Change to Via point': intl.formatMessage({
        id: 'Change to Via point',
        defaultMessage: 'Change to Via point',
      }),
      'Change to Drag point': intl.formatMessage({
        id: 'Change to Drag point',
        defaultMessage: 'Change to Drag point',
      }),
      'Change to End point': intl.formatMessage({
        id: 'Change to End point',
        defaultMessage: 'Change to End point',
      }),
      'Remove this part': intl.formatMessage({
        id: 'Remove this part',
        defaultMessage: 'Remove this part',
      }),
      'Concat to prev part': intl.formatMessage({
        id: 'Concat to prev part',
        defaultMessage: 'Concat to prev part',
      }),
      'Set club address': intl.formatMessage({
        id: 'Set club address',
        defaultMessage: 'Set club address',
      }),
      'Create club': intl.formatMessage({
        id: 'Create club',
        defaultMessage: 'Create club',
      }),
      'Set bikerbed address': intl.formatMessage({
        id: 'Set bikerbed address',
        defaultMessage: 'Set bikerbed address',
      }),
      'Create BikerBed': intl.formatMessage({
        id: 'Create BikerBed',
        defaultMessage: 'Create BikerBed',
      }),
      'Set place address': intl.formatMessage({
        id: 'Set place address',
        defaultMessage: 'Set place address',
      }),
      'Create place': intl.formatMessage({
        id: 'Create place',
        defaultMessage: 'Create place',
      }),
      'Point address is loading': intl.formatMessage({
        id: 'Point address is loading',
        defaultMessage: 'Point address is loading',
      }),
      'Create tour': intl.formatMessage({
        id: 'Create tour',
        defaultMessage: 'Create tour',
      }),
      'View tour': intl.formatMessage({
        id: 'View tour',
        defaultMessage: 'View tour',
      }),
      'Hide home position': intl.formatMessage({
        id: 'Hide home position',
        defaultMessage: 'Hide home position',
      }),
      'Show home position': intl.formatMessage({
        id: 'Show home position',
        defaultMessage: 'Show home position',
      }),
      'Hide work position': intl.formatMessage({
        id: 'Hide work position',
        defaultMessage: 'Hide work position',
      }),
      'Show work position': intl.formatMessage({
        id: 'Show work position',
        defaultMessage: 'Show work position',
      }),
      Optimize: intl.formatMessage({
        id: 'Optimize',
        defaultMessage: 'Optimize',
      }),
      Undo: intl.formatMessage({ id: 'Undo', defaultMessage: 'Undo' }),
      'Show on Google Maps': intl.formatMessage({
        id: 'Show on Google Maps',
        defaultMessage: 'Show on Google Maps',
      }),
      'Remove shaping points': intl.formatMessage({
        id: 'Remove shaping points',
        defaultMessage: 'Remove shaping points',
      }),
      'Cut and divide route': intl.formatMessage({
        id: 'Cut and divide route',
        defaultMessage: 'Cut and divide route',
      }),
      'Create a Place': intl.formatMessage({
        id: 'Create a Place',
        defaultMessage: 'Create a Place',
      }),
    };
    return <WrappedComponent rightMenusText={rightMenusText} {...props} />;
  };
};

export default withRightMenuText;
