const axios = require('axios');

const url = `${process.env.API_URL}/api`;

const handleResponse = (response) => {
  return new Promise((resolve, reject) => {
    const { data } = response;
    if (response.status !== 200) {
      const error = (data && data.message) || response.statusText;
      return reject(error);
    }
    return resolve(data);
  });
};

function add(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    data,
  };

  return axios(`${url}/news.add`, requestOptions).then(handleResponse);
}

export const newsAPI = {
  add,
};
