import { Type } from '../../../constants/TourPoints';
import mapUtils from '../../../libs/Utils/mapUtils';
import './map_contextmenu.css';

import * as types from 'app/actions/action_types.js';

import { publish } from 'app/libs/core/PubSub';
import * as way from 'app/libs/core/PubSubWays';

import store, { history } from 'app/store';
import { changeTourMode } from 'actions/Map/tour.full.data.action';
import { setPassedPoint } from 'actions/Map/map.scope.action';
import { PlacePointGet } from 'actions/Place/place.action';
import {
  setHomeAddressUser,
  setWorkAddressUser,
  changeShowUserHome,
  changeShowUserWork,
  changeUserMainData,
} from '../../../actions/Users/users.action';
import { setAddressClub } from '../../../actions/Club/club_full_view.action';
import { getCustomId } from '../../../libs/core/Unique';
import { getIdFromUrl } from '../../Utility/getIdFromUrl';

export const updateContextMenuItems = (map, type, rightMenusText = {}) => {
  if (map && map.contextmenu) {
    map.contextmenu.removeAllItems();
    getDriveContextMenuItems(rightMenusText).forEach((el) =>
      map.contextmenu.insertItem(el)
    );
  }
  // return map;
};

const visibleHomeContextMenu = (rightMenusText, items) => {
  const { showHome } = store.getState().userReducer;
  return [
    showHome
      ? {
          text: rightMenusText['Hide home position'],
          iconCls: 'map_main_cm_home_pos',
          callback: () => visibleHomePosition(rightMenusText, items),
        }
      : {
          text: rightMenusText['Show home position'],
          iconCls: 'map_main_cm_home_pos',
          callback: () => visibleHomePosition(rightMenusText, items),
        },
  ];
};

const visibleWorkContextMenu = (rightMenusText, items) => {
  const { showWork } = store.getState().userReducer;
  return [
    showWork
      ? {
          text: rightMenusText['Hide work position'],
          iconCls: 'map_main_cm_work_pos',
          callback: () => visibleWorkPosition(rightMenusText, items),
        }
      : {
          text: rightMenusText['Show work position'],
          iconCls: 'map_main_cm_work_pos',
          callback: () => visibleWorkPosition(rightMenusText, items),
        },
  ];
};

const __homeWorkContextMenuItems = (rightMenusText, items) => {
  return [
    '-',
    {
      text: rightMenusText['Set home position'],
      iconCls: 'map_main_cm_home_pos',
      callback: (e) => setHomePosition(e, rightMenusText, items),
    },
    ...visibleHomeContextMenu(rightMenusText, items),
    {
      text: rightMenusText['Set work position'],
      iconCls: 'map_main_cm_work_pos',
      callback: (e) => setWorkPosition(e, rightMenusText, items),
    },
    ...visibleWorkContextMenu(rightMenusText, items),
    '-',
    {
      text: rightMenusText['Show on Google Maps'],
      iconCls: 'map_view_tour_show',
      callback: (e) => showOnGoogleMaps(e),
    },
  ];
};

export const getTourViewContextMenuItems = (rightMenusText) => {
  return [
    {
      text: rightMenusText.Edit,
      iconCls: 'map_view_tour_edit_mode',
      callback: switchEditMode,
    },
    ...__homeWorkContextMenuItems(rightMenusText, getTourViewContextMenuItems),
  ];
};

export const getDriveContextMenuItems = (rightMenusText) => {
  return [
    {
      text: rightMenusText['Set start point'],
      iconCls: 'map_drive_point_mark_start',
      callback: setStartPoint,
    },
    {
      text: rightMenusText['Set POI'],
      iconCls: 'map_drive_point_mark_via',
      callback: setViaPoint,
    },
    {
      text: rightMenusText['Set end point'],
      iconCls: 'map_drive_point_mark_end',
      callback: setEndPoint,
    },
    ...__homeWorkContextMenuItems(rightMenusText, getDriveContextMenuItems),
  ];
};

export const driveContextMenu = (rightMenusText) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText['Set start point'],
        // text: '<div style="font-size:14px; height: 50px;">Create tour point</div>',
        // icon: pointStart,
        iconCls: 'map_drive_point_mark_start',
        callback: setStartPoint,
      },
      {
        text: rightMenusText['Set POI'],
        iconCls: 'map_drive_point_mark_via',
        callback: setViaPoint,
      },
      {
        text: rightMenusText['Set end point'],
        iconCls: 'map_drive_point_mark_end',
        callback: setEndPoint,
      },
      '-',
      {
        text: rightMenusText['Set home position'],
        iconCls: 'map_main_cm_home_pos',
        callback: (e) => setHomePosition(e, rightMenusText, items),
      },
      {
        text: rightMenusText['Set work position'],
        iconCls: 'map_main_cm_work_pos',
        callback: (e) => setWorkPosition(e, rightMenusText, items),
      },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e),
      },
    ],
  };
};

export const getTourContextMenuItems = (rightMenusText) => {
  return [
    {
      text: rightMenusText['Start a tour from here'],
      iconCls: 'map_main_cm_tour_point',
      callback: tourStartHere,
    },
    ...__homeWorkContextMenuItems(rightMenusText, getTourContextMenuItems),
  ];
};

export const drivePoiContexMenu = (rightMenusText, point) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText['Set start point'],
        iconCls: 'map_drive_point_mark_start',
        callback: setStartPointFromPoi,
      },
      {
        text: rightMenusText['Set via point'],
        iconCls: 'map_drive_point_mark_via',
        callback: setViaPointFromPoi,
      },
      {
        text: rightMenusText['Set end point'],
        iconCls: 'map_drive_point_mark_end',
        callback: setEndPointFromPoi,
      },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e, point),
      },
    ],
  };
};
export const driveCurrentLocationContextMenu = (rightMenusText, point) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: 'Set start point',
        iconCls: 'map_drive_point_mark_start',
        callback: setStartPointFromCurrentPosition,
      },
      {
        text: 'Set via point',
        iconCls: 'map_drive_point_mark_via',
        callback: setViaPointFromCurrentPosition,
      },
      {
        text: 'Set end point',
        iconCls: 'map_drive_point_mark_end',
        callback: setEndPointFromCurrentPosition,
      },
      '-',
      {
        text: 'Set home position',
        iconCls: 'map_main_cm_home_pos',
        callback: (e) => setHomePosition(e, rightMenusText, items),
      },
      {
        text: 'Set work position',
        iconCls: 'map_main_cm_work_pos',
        callback: (e) => setWorkPosition(e, rightMenusText, items),
      },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e, point),
      },
    ],
  };
};
export const driveEditablePointCM = (rightMenusText, point) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText['Remove point'],
        iconCls: 'map_view_tour_delete',
        callback: removeTourPoint,
      },
      {
        text: rightMenusText['Edit point'],
        iconCls: 'map_view_tour_edit_mode',
        callback: editTourPoint,
      },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e, point),
      },
    ],
  };
};
export const getDriveStartPointCM = (rightMenusText, point) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText['Remove point'],
        iconCls: 'map_view_tour_delete',
        callback: removeTourPoint,
      },
      {
        text: rightMenusText['Change to Via point'],
        iconCls: 'map_drive_point_mark_via',
        callback: setViaPointType,
      },
      {
        text: rightMenusText['Change to End point'],
        iconCls: 'map_drive_point_mark_end',
        callback: setEndPointType,
      },
      {
        text: rightMenusText['Edit point'],
        iconCls: 'map_view_tour_edit_mode',
        callback: editTourPoint,
      },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e, point),
      },
    ],
  };
};

export const getDriveStaticPointCM = (rightMenusText, point) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText.Edit,
        iconCls: 'map_view_tour_edit_mode',
        callback: switchEditMode,
      },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e, point),
      },
    ],
  };
};

export const getDriveEndPointCM = (rightMenusText, point) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText['Remove point'],
        iconCls: 'map_view_tour_delete',
        callback: removeTourPoint,
      },
      {
        text: rightMenusText['Change to Via point'],
        iconCls: 'map_drive_point_mark_via',
        callback: setViaPointType,
      },
      {
        text: rightMenusText['Change to Start point'],
        iconCls: 'map_drive_point_mark_start',
        callback: setStartPointType,
      },
      {
        text: rightMenusText['Edit point'],
        iconCls: 'map_view_tour_edit_mode',
        callback: editTourPoint,
      },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e, point),
      },
    ],
  };
};

export const getDriveViaPointCM = (rightMenusText, point) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText['Change to Start point'],
        iconCls: 'map_drive_point_mark_start',
        callback: setStartPointType,
      },
      {
        text: rightMenusText['Change to Drag point'],
        iconCls: 'map_drive_point_mark_drag',
        callback: setDragPointType,
      },
      {
        text: rightMenusText['Change to End point'],
        iconCls: 'map_drive_point_mark_end',
        callback: setEndPointType,
      },
      {
        text: rightMenusText['Remove point'],
        iconCls: 'map_view_tour_delete',
        callback: removeTourPoint,
      },
      {
        text: rightMenusText['Edit point'],
        iconCls: 'map_view_tour_edit_mode',
        callback: editTourPoint,
      },
      {
        text: rightMenusText['Cut and divide route'],
        iconCls: 'map_view_tour_divide',
        callback: cutRoute,
      },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e, point),
      },
    ],
  };
};

export const getDriveShapPointCM = (rightMenusText, point, nextPointId) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText['Change to Via point'],
        iconCls: 'map_drive_point_mark_via',
        callback: setViaPoint,
      },
      {
        text: rightMenusText['Change to Drag point'],
        iconCls: 'map_drive_point_mark_drag',
        callback: (e) => setDragPoint(e, nextPointId),
      },
      {
        text: rightMenusText['Remove shaping points'],
        iconCls: 'map_view_tour_delete',
        disabled: isRemoveShapingEnable(),
        callback: removeShapingPoint,
      },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e, point),
      },
    ],
  };
};

export const getDriveDragPointCM = (rightMenusText, point) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText['Remove point'],
        iconCls: 'map_view_tour_delete',
        callback: removeTourPoint,
      },
      {
        text: rightMenusText['Change to Via point'],
        iconCls: 'map_drive_point_mark_via',
        callback: setViaPointType,
      },
      {
        text: rightMenusText['Cut and divide route'],
        iconCls: 'map_view_tour_divide',
        callback: cutRoute,
      },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e, point),
      },
    ],
  };
};

export const partStartPointCM = (rightMenusText, point) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText['Remove this part'],
        iconCls: 'map_view_tour_delete',
        callback: removeThisPart,
      },
      // {
      //     text: rightMenusText['Concat to prev part'],
      //     callback: concatToPrevPart
      // },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e, point),
      },
    ],
  };
};

export const partEndPointCM = (rightMenusText, point) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText['Remove this part'],
        iconCls: 'map_view_tour_delete',
        callback: removeThisPart,
      },
      // {
      //     text: rightMenusText['Concat to next part'],
      //     callback: concatToNextPart
      // },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e, point),
      },
    ],
  };
};
export const getClubAllContextMenuItems = (rightMenusText) => {
  return [
    {
      text: rightMenusText['Create club'],
      iconCls: 'map_main_cm_my_place',
      callback: () => 'create club here',
    },
    ...__homeWorkContextMenuItems(rightMenusText, getClubAllContextMenuItems),
  ];
};
export const getClubCreateContextMenuItems = (rightMenusText) => {
  return [
    {
      text: rightMenusText['Set club address'],
      iconCls: 'map_main_club',
      callback: (e) => setClubPosition(e, rightMenusText),
    },
    ...__homeWorkContextMenuItems(
      rightMenusText,
      getClubCreateContextMenuItems
    ),
  ];
};

export const getClubViewContextMenuItems = (rightMenusText) => {
  return [
    {
      text: rightMenusText['Create tour'],
      iconCls: 'map_main_cm_tour_point',
      callback: createTourFromClub,
    },
    ...__homeWorkContextMenuItems(rightMenusText, getClubViewContextMenuItems),
  ];
};
export const getBedAllContextMenuItems = (rightMenusText) => {
  return [
    {
      text: rightMenusText['Create BikerBed'],
      iconCls: 'map_main_cm_my_place',
      callback: () => 'create club here',
    },
    ...__homeWorkContextMenuItems(rightMenusText, getBedAllContextMenuItems),
  ];
};
export const getBedCreateContextMenuItems = (rightMenusText) => {
  return [
    {
      text: rightMenusText['Set bikerbed address'],
      iconCls: 'map_main_club',
      callback: (e) => setBedPosition(e, rightMenusText),
    },
    ...__homeWorkContextMenuItems(rightMenusText, getBedCreateContextMenuItems),
  ];
};
export const getBedViewContextMenuItems = (rightMenusText) => {
  return [
    {
      text: rightMenusText['Create tour'],
      iconCls: 'map_main_cm_tour_point',
      callback: createTourFromClub,
    },
    ...__homeWorkContextMenuItems(rightMenusText, getBedViewContextMenuItems),
  ];
};

export const getProfileContextMenuItems = (rightMenusText) => {
  return [
    {
      text: rightMenusText['Start a tour from here'],
      iconCls: 'map_main_cmtour_point',
      callback: tourStartHere,
    },
    ...__homeWorkContextMenuItems(rightMenusText, getProfileContextMenuItems),
  ];
};

export const clubDeactPointCM = (rightMenusText, point) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText['Remove point'],
        iconCls: 'map_view_tour_delete',
        callback: removeClubPoint,
      },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e, point),
      },
    ],
  };
};

// Place
export const placeEditContextMenuItems = (rightMenusText, point) => [
  {
    text: rightMenusText['Set place address'],
    iconCls: 'map_main_cm_my_place',
    callback: (point) => {
      const { lat, lng } = point.relatedTarget
        ? point.relatedTarget._latlng
        : point.latlng;
      store.dispatch(
        PlacePointGet({ value: 'Point address is loading...', lat, lng })
      );
      setAddress(PlacePointGet, point);
    },
  },
  '-',
  {
    text: rightMenusText['Show on Google Maps'],
    iconCls: 'map_view_tour_show',
    callback: (e) => showOnGoogleMaps(e, point),
  },
];

export const placeViewContextMenuItems = (rightMenusText) => [
  {
    text: rightMenusText['Create a Place'],
    iconCls: 'map_main_cm_my_place',
    callback: (e) => goToCreatePlace(e),
  },
  '-',
  {
    text: rightMenusText['Show on Google Maps'],
    iconCls: 'map_view_tour_show',
    callback: (e) => showOnGoogleMaps(e),
  },
];

export const placeDriveEditContextMenuItems = (rightMenusText) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText['Set as Via'],
        callback: () => console.log('Set as Via'),
      },
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e),
      },
    ],
  };
};

//

export const getDriveHomeWorkPointCM = (rightMenusText, point) => {
  return {
    contextmenu: true,
    contextmenuInheritItems: false,
    contextmenuItems: [
      {
        text: rightMenusText['Start a tour from here'],
        iconCls: 'map_main_cm_tour_point',
        callback: tourStartHere,
      },
      '-',
      {
        text: rightMenusText['Set home position'],
        iconCls: 'map_main_cm_home_pos',
        callback: (e) =>
          setHomePosition(e, rightMenusText, getDriveHomeWorkPointCM),
      },
      ...visibleHomeContextMenu(rightMenusText, getDriveHomeWorkPointCM),
      {
        text: rightMenusText['Set work position'],
        iconCls: 'map_main_cm_work_pos',
        callback: (e) =>
          setWorkPosition(e, rightMenusText, getDriveHomeWorkPointCM),
      },
      ...visibleWorkContextMenu(rightMenusText, getDriveHomeWorkPointCM),
      '-',
      {
        text: rightMenusText['Show on Google Maps'],
        iconCls: 'map_view_tour_show',
        callback: (e) => showOnGoogleMaps(e, point),
      },
    ],
  };
};

function cutRoute(e) {
  publish(way.DRIVE_BUILDER_PART_DEVIDE_BY_POINT, {
    customId: e.relatedTarget.options.customId,
  });
}
function removeTourPoint(e) {
  publish(way.DRIVE_MAP_POINT_REMOVE, {
    customId: e.relatedTarget.options.customId,
  });
}
function setPointType(e) {
  publish(way.DRIVE_MAP_POINT_SET_TYPE, {
    customId: e.relatedTarget.options.customId,
  });
}
function setStartPointType(e) {
  publish(way.DRIVE_MAP_POINT_SET_TYPE, {
    customId: e.relatedTarget.options.customId,
    type: Type.START,
  });
}
function setEndPointType(e) {
  publish(way.DRIVE_MAP_POINT_SET_TYPE, {
    customId: e.relatedTarget.options.customId,
    type: Type.END,
  });
}
function setViaPointType(e) {
  publish(way.DRIVE_MAP_POINT_SET_TYPE, {
    customId: e.relatedTarget.options.customId,
    type: Type.VIA,
  });
}
function setDragPointType(e) {
  publish(way.DRIVE_MAP_POINT_SET_TYPE, {
    customId: e.relatedTarget.options.customId,
    type: Type.DRAG,
  });
}
function editTourPoint(e) {
  publish(way.DRIVE_MAP_STATIC_POINT_SELECTED, {
    customId: e.relatedTarget.options.customId,
  });
}

function callback(e) {
  alert(e.latlng);
}

function addToMyPlace(point) {
  const { lat, lng } = point.relatedTarget
    ? point.relatedTarget._latlng
    : point.latlng;

  store.dispatch(
    PlacePointGet({ value: 'Point address is loading...', lat, lng })
  );
  setAddress(PlacePointGet, point);
  history.push('/place/create');
}

export function tourStartHere(point, type = 'start') {
  store.dispatch(setPassedPoint({ ...point.latlng }, type));
  history.push('/createtour');
}
function addTourPoint(e) {
  const { lat, lng } = e.latlng;
  publish(way.DRIVE_MAP_CLICK, { lat, lng, draggable: true });
}

function setStartPointFromCurrentPosition(e) {
  const { lat, lng } = e.relatedTarget._latlng;
  publish(way.DRIVE_MAP_CLICK, { lat, lng, draggable: true, type: 'start' });
}
function setViaPointFromCurrentPosition(e) {
  const { lat, lng } = e.relatedTarget._latlng;
  publish(way.DRIVE_MAP_CLICK, { lat, lng, draggable: true, type: 'via' });
}
function setEndPointFromCurrentPosition(e) {
  const { lat, lng } = e.relatedTarget._latlng;
  publish(way.DRIVE_MAP_CLICK, { lat, lng, draggable: true, type: 'end' });
}

function setStartPointFromPoi(e) {
  const iconType = e.relatedTarget.options.icon.options.className.split('_')[1];
  const { lat, lng } = e.relatedTarget._latlng;
  publish(way.DRIVE_MAP_CLICK, {
    lat,
    lng,
    draggable: false,
    iconType,
    type: 'start',
  });
}
function setViaPointFromPoi(e) {
  const iconType = e.relatedTarget.options.icon.options.className.split('_')[1];
  const { lat, lng } = e.relatedTarget._latlng;
  publish(way.DRIVE_MAP_CLICK, {
    lat,
    lng,
    draggable: false,
    iconType,
    type: 'via',
  });
}
function setEndPointFromPoi(e) {
  const iconType = e.relatedTarget.options.icon.options.className.split('_')[1];
  const { lat, lng } = e.relatedTarget._latlng;
  publish(way.DRIVE_MAP_CLICK, {
    lat,
    lng,
    draggable: false,
    iconType,
    type: 'end',
  });
}

export function setStartPoint(e) {
  const { lat, lng } = e.latlng;
  publish(way.DRIVE_MAP_CLICK, { lat, lng, draggable: true, type: 'start' });
}
export function setViaPoint(e) {
  const { lat, lng } = e.latlng;
  publish(way.DRIVE_MAP_CLICK, { lat, lng, draggable: true, type: 'via' });
}
export function setEndPoint(e) {
  const { lat, lng } = e.latlng;
  publish(way.DRIVE_MAP_CLICK, { lat, lng, draggable: true, type: 'end' });
}
export function setDragPoint(e, nextPointId) {
  const { lat, lng } = e.latlng;
  publish(way.DRIVE_MAP_CLICK, {
    lat,
    lng,
    draggable: true,
    type: 'drag',
    nextPointId,
  });
}

export function setViaPointWithIcon(e, place_type, place_id) {
  const { lat, lng } = e.latlng;
  publish(way.DRIVE_MAP_CLICK, {
    lat,
    lng,
    draggable: false,
    type: 'via',
    place_type,
    place_id,
  });
}

function removeThisPart(e) {
  // let partId = e.relatedTarget.options.partId;
  const { customId } = e.relatedTarget.options;
  // console.log('removeThisPart', partId, e);
  publish(way.BUILDER_MAP_REMOVE_PART, { customId });
}
function concatToPrevPart(e) {
  console.log('concatToPrevPart', e);
}
function concatToNextPart(e) {
  console.log('concatToNextPart', e);
}
function switchEditMode(e) {
  store.dispatch((dispatch) => {
    dispatch(changeTourMode(true));
  });
}

export function setHomePosition(e, rightMenusText, items) {
  const { router, authentication } = store.getState();
  const { user } = authentication;
  const { location } = router;
  const ch =
    location.pathname.includes('profile') &&
    user.id === getIdFromUrl(location.pathname);

  setAddress(setHomeAddressUser, e, ch, 'home', rightMenusText, items);
}
export function setWorkPosition(e, rightMenusText, items) {
  const { router, authentication } = store.getState();
  const { user } = authentication;
  const { location } = router;
  const ch =
    location.pathname.includes('profile') &&
    user.id === getIdFromUrl(location.pathname);

  setAddress(setWorkAddressUser, e, ch, 'work', rightMenusText, items);
}

// BIBERBED
export function setBedPosition(e, intl = {}) {
  const { lat, lng } = e.latlng;
  store.dispatch(
    setAddressClub({
      value: intl.formatMessage
        ? `${intl.formatMessage({
            id: 'Point address is loading',
            defaultMessage: 'Point address is loading',
          })}...`
        : `${intl['Point address is loading']}...`,
      lat,
      lng,
    })
  );
  setAddress(setAddressClub, e);
  publish(way.BIKERBED_MAP_CLEAN);
  publish(way.BIKERBED_POINT_CREATE, {
    customId: getCustomId(),
    isLoading: false,
    point: { lat, lng, draggable: true, type: 'bed_deact' },
  });
}

function removeBedPoint(e) {
  publish(way.BIKERBED_MAP_POINT_REMOVE, {
    customId: e.relatedTarget.options.customId,
  });
  store.dispatch(setAddressClub({ value: '', lat: '', lng: '' }));
}

// CLUB
export function setClubPosition(e, intl = {}) {
  console.log(e);
  const { lat, lng } = e.latlng;
  store.dispatch(
    setAddressClub({
      value: intl.formatMessage
        ? `${intl.formatMessage({
            id: 'Point address is loading',
            defaultMessage: 'Point address is loading',
          })}...`
        : `${intl['Point address is loading']}...`,
      lat,
      lng,
    })
  );
  setAddress(setAddressClub, e);
  publish(way.CLUB_MAP_CLEAN);
  publish(way.CLUB_POINT_CREATE, {
    customId: getCustomId(),
    isLoading: false,
    point: { lat, lng, draggable: true, type: 'club_deact' },
  });
}

function removeClubPoint(e) {
  publish(way.CLUB_MAP_POINT_REMOVE, {
    customId: e.relatedTarget.options.customId,
  });
  store.dispatch(setAddressClub({ value: '', lat: '', lng: '' }));
}

//

export function setPlaceAddress(e) {
  const { lat, lng } = e.latlng;
  store.dispatch(
    PlacePointGet({ value: 'Point address is loading...', lat, lng })
  );
  setAddress(PlacePointGet, e);
}

function createTourFromClub() {
  const state = store.getState();
  const { admin } = state.clubFullDataReducer;
  const option = admin ? { prevUrl: state.router.location.pathname } : {};
  history.push('/createtour', option);
}
export function setAddress(
  func,
  e,
  ch = false,
  scope = null,
  rightMenusText = null,
  items = null
) {
  const { lat, lng } = e.relatedTarget ? e.relatedTarget._latlng : e.latlng;
  const customId = e.relatedTarget ? e.relatedTarget.options.customId : null;
  mapUtils.getTourstartGeocoder({ id: customId, lat, lng }, (e) => {
    const address = mapUtils.formateTourstartAddress(e.data);
    const saveData = (lat, lng) => {
      store.dispatch(
        func({
          value: address || '',
          lat,
          lng,
          country: address ? e.data.features[0].properties.countrycode : '',
        })
      );
      if (ch && scope) {
        if (scope === 'home') {
          store.dispatch(
            changeUserMainData({
              ...store.getState().userReducer.data,
              address: address || '',
              lat,
              lng,
              country: address ? e.data.features[0].properties.countrycode : '',
            })
          );
          visibleHomePosition(rightMenusText, items, 'set');
        } else {
          store.dispatch(
            changeUserMainData({
              ...store.getState().userReducer.data,
              work_address: {
                ...store.getState().userReducer.data.work_address,
                value: address || '',
                lat,
                lng,
                country: address
                  ? e.data.features[0].properties.countrycode
                  : '',
              },
            })
          );
          visibleWorkPosition(rightMenusText, items, 'set');
        }
      }
    };

    stickToRoad(lat, lng, saveData);
  });
}

export function stickToRoad(lat, lng, callback) {
  mapUtils.stickToRoad(
    {
      nearest: { lat, lng },
    },
    (e) => {
      if (typeof e.mapped_coordinate !== 'undefined') {
        callback(e.mapped_coordinate[0], e.mapped_coordinate[1]);
      } else {
        console.error(e);
        callback(lat, lng);
      }
    }
  );
}
function updateContext(context, items, rightMenusText) {
  if (context) {
    context.removeAllItems();
    const list = items(rightMenusText);
    if (list && list.length > 0) list.forEach((el) => context.insertItem(el));
  }
}
function visibleHomePosition(rightMenusText, items, scope = null) {
  const { showHome, homeAddress } = store.getState().userReducer;
  const { lat, lng } = homeAddress;

  if (!lat && lng) return;

  if (showHome) {
    publish(way.TOOL_MAP_SHOW_HOME_STATIC_ICON, {
      lat,
      lng,
      iconType: Type.HOME,
    });
  } else {
    publish(way.TOOL_MAP_SHOW_HOME_STATIC_ICON, {});
  }

  store.dispatch(changeShowUserHome(scope === 'set' ? true : !showHome));

  publish(way.GET_MAP_CONTEXT_MENU, { updateContext, items, rightMenusText });
}
function visibleWorkPosition(rightMenusText, items, scope = null) {
  const { showWork, workAddress } = store.getState().userReducer;
  const { lat, lng } = workAddress;

  if (!lat && lng) return;

  if (showWork) {
    publish(way.TOOL_MAP_SHOW_WORK_STATIC_ICON, {
      lat,
      lng,
      iconType: Type.WORK,
    });
  } else {
    publish(way.TOOL_MAP_SHOW_WORK_STATIC_ICON, {});
  }
  store.dispatch(changeShowUserWork(scope === 'set' ? true : !showWork));
  publish(way.GET_MAP_CONTEXT_MENU, { updateContext, items, rightMenusText });
}

function showOnGoogleMaps(e, point = null) {
  console.log(point);
  const { lat, lng } = point ? point._latlng : e.latlng;
  const { zoom } = store.getState().mapPostition;
  const url = `https://www.google.com/maps?q=${lat},${lng}&z=${zoom}`;
  window.open(url, '_blank').focus();
}

function goToCreatePlace() {
  // store.dispatch(setPassedPoint({ ...point.latlng }, type));
  history.push('/place/create');
}

function isRemoveShapingEnable() {
  const { ferry, highway } = store.getState().tourFullData;
  return ferry || highway;
}

function removeShapingPoint() {
  store.dispatch({
    type: types.TOUR_FULL_DATA_SWITCH_SHAPING_POINTS,
    payload: false,
  });
  setTimeout(() => {
    publish(way.SAVE_TB_SWITCH_SHAPING_UNPAVED_ROUTES, {});
  }, 0);
}
