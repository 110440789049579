import * as type from '../action_types';

export const mainMenuCollapse = (data) => {
  return {
    type: type.MAIN_MENU_COLLAPSE,
    payload: data,
  };
};

export const setMenuFolderCreateVisible = (data) => {
  return {
    type: type.SET_MENU_FOLDER_CREATE_VISIBLE,
    payload: data,
  };
};
