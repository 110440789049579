import React from 'react';
import { Button } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

const ImagesGallery = (props) => {
  const { images, deletePictures } = props;
  if (images.length > 0) {
    return (
      <div className="previewUploadImage">
        {images.map((item) => {
          return (
            <div className="imgLayout" key={item.src}>
              <Button
                type="primary"
                ghost
                shape="circle"
                className="deletePicturesBtn"
                onClick={() => deletePictures(item)}
              >
                <DeleteFilled />
              </Button>
              <img src={item.src} alt="post_image" />
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

export default ImagesGallery;
