import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Divider } from 'antd';

import withTranslations from 'app/components/HOGs/withTranslations';
import { isMobileOnly } from 'react-device-detect';
import { publish } from 'app/libs/core/PubSub';
import * as way from 'app/libs/core/PubSubWays';

import pageCache from 'app/API/cache.service';
import { pageScope, URL } from 'app/constants/mapConstants';

import { useActions } from 'app/components/Utility/hooks/useAction.hooks';
import { openNotificationWithIcon } from 'app/components//Utility/notificationMessage';
import { history } from 'app/store';
import { clubAPI } from 'app/API/club.api';

import MobileClub from '../../../Mobile/BikeClub/bikeclubmobile';
import AboutClub from './About';
import Contact from './Contact';
import Pictures from './Pictures';
import Videos from './Videos';
import DrawerBikeClub from './DrawerBikeClub';
import Cover from './Cover';
import Subdomain from './BikeClubSubDomain';
import SaveClub, { urlTitle } from './Save';
import Title from './Title';

const BikeClubCreate = (props) => {
  const { tr, page } = props;

  const {
    setEditModeClub,
    setDataClub,
    setTypeMouseCursor,
    loginModalOpen,
    setDubData,
    scope,
  } = useActions();

  const [form] = Form.useForm();
  const userId = useSelector((store) => store.authentication.user.id);
  const isLoggedIn = useSelector((store) => store.authentication.isLoggedIn);
  const clubData = useSelector((store) => store.clubFullDataReducer.data);

  const [emptyLogoError, setEmptyLogoError] = useState(false);

  useEffect(() => {
    setEditModeClub(true);
    scope(pageScope.club_view);
    if (page === 'create') {
      setDataClub({});
    }
    if (page === 'edit') {
      setDubData({ ...clubData });
    }
    return () => {
      publish(way.CLUB_EDIT_GET_ADDRESS_FROM_MAP_OFF);
      setTypeMouseCursor('');
      setEditModeClub(false);
      publish(way.CLUB_MAP_CLEAN);
    };
  }, []);

  useEffect(() => {
    if (page === 'create' && clubData.id) {
      setDataClub({});
      setDubData({});
      form.resetFields();
    }
  }, [page]);

  const clearCache = () => {
    pageCache.destroy(URL.CLUB.ALL[0]);
    pageCache.destroy(URL.CLUB.MY[0]);
    pageCache.destroy(URL.CONTENT.BEST[0]);
  };

  const saveChangesAction = () => {
    const successText = tr['Your changes are saved'];
    openNotificationWithIcon('success', successText, 3);
    history.push(`/club/${urlTitle(clubData.title)}_${clubData.id}`);
    clearCache();
  };

  const handleSubmit = (values) => {
    if (!isLoggedIn) {
      loginModalOpen(true);
    } else if (isLoggedIn) {
      if (!clubData.logo) {
        setEmptyLogoError(true);
        return;
      }
      if (page === 'edit') {
        const data = {
          title: values.name,
          description: values.about,
          link: values.website,
          contacts: values.additional,
          address: values.address,
        };
        if (clubData.country) {
          data.country = clubData.country;
        }
        if (typeof clubData.logo === 'object') {
          data.logo = clubData.logo.id;
        }
        if (typeof clubData.background === 'object') {
          data.background = clubData.background.id;
        }
        clubAPI
          .updateClub(clubData.id, data, clubData.lat, clubData.lng, 'club')
          .then((res) => saveChangesAction(res))
          .catch((err) => console.log(err));
      } else if (page === 'create') {
        const data = {
          ...clubData,
          title: values.name,
          description: values.about,
          link: values.website,
          contacts: values.additional,
          address: values.address,
          scope: 'club',
          owner: userId,
          background: clubData?.background?.id || 0,
          logo: clubData?.logo?.id || 0,
          country: clubData.country,
        };

        clubAPI
          .createClub(data)
          .then((res) => {
            const { code, response } = res;
            if (code === 200) {
              history.push(`/club/${urlTitle(values.name)}_${response}`);
              clearCache();
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handlerFinishFailed = ({ errorFields }) => {
    if (!clubData.logo) {
      setEmptyLogoError(true);
    }
    console.log(errorFields);
  };

  const GetDivider = () => {
    return (
      <div className="divider_layout">
        <Divider className="divider" />
      </div>
    );
  };

  const getForm = () => {
    return (
      <div className="biker_club_layout edit">
        <Form
          onFinish={handleSubmit}
          onFinishFailed={handlerFinishFailed}
          form={form}
        >
          <Cover
            form={form}
            emptyLogoError={emptyLogoError}
            setEmptyLogoError={setEmptyLogoError}
          />
          <Title form={form} />
          <GetDivider />
          <Contact form={form} />
          <GetDivider />
          <AboutClub form={form} />
          <GetDivider />
          {page === 'edit' && (
            <>
              <Subdomain clubData={clubData} form={form} />
              <GetDivider />
            </>
          )}
          {page === 'create' && (
            <>
              <Pictures form={form} />
              <Videos form={form} />
            </>
          )}
          <SaveClub page={page} />
        </Form>
      </div>
    );
  };

  return isMobileOnly ? (
    <MobileClub>{getForm()}</MobileClub>
  ) : (
    <DrawerBikeClub>{getForm()}</DrawerBikeClub>
  );
};

export default withTranslations(BikeClubCreate);
