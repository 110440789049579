import React from 'react';
import { Form, Input } from 'antd';
import withTranslations from 'app/components/HOGs/withTranslations';

const FormItem = (props) => {
  const { lang, multy, fullName, tr } = props;

  const getTitlePlaceholder = !multy
    ? tr['Headline (optionally)']
    : `${tr.Headline} (${fullName})`;

  const getTextPlaceholder = !multy
    ? tr['What is on your mind?']
    : `${tr['What is on your mind?']} (${fullName})`;

  return (
    <>
      <Form.Item name={['title', lang]} className="title_item">
        <Input placeholder={getTitlePlaceholder} className="title_input" />
      </Form.Item>
      <Form.Item name={['text', lang]} className="text_item">
        <Input.TextArea
          placeholder={getTextPlaceholder}
          className="text_area"
        />
      </Form.Item>
    </>
  );
};

export default withTranslations(FormItem);
